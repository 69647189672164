
type Props = {
  components: {componentName: string; formattedDaysRemainingStatus: string; formattedLastChangeDate: string}[];
  enabled: boolean;
};

export function AssetComponentLifeSection({components, enabled}:Props) {

  const onClickTable = () => {
    window.openComponentLifeModal();
  };
  if(!enabled) return null;
  return (
    <div onClick={onClickTable} style={{cursor: 'pointer'}} className="table-responsive component-life-container @enabledClassName" title="Click to open component life" data-toggle="tooltip">
      <h2 style={{ fontWeight: 'bold' }} className="text-center">
        <span>Component Life</span>
      </h2>
      <table className="table table-invoice">
        <thead style={{padding: '0 0 20px'}}>
        <tr>
          <th>Component</th>
          <th>Days Remaining</th>
          <th>Last Change</th>
        </tr>
        </thead>
        <tbody id="component-life-asset-table-body">
        {components.map(c => (
          <tr key={c.componentName}>
            <td>{c.componentName}</td>
            <td>{c.formattedDaysRemainingStatus}</td>
            <td>{c.formattedLastChangeDate}</td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
}
