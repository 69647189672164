export function pickFile(accept: string, multiple: boolean = false, capture: boolean = false) {
  return new Promise<File[]>((resolve, reject) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = accept;
    fileInput.style.display = 'none';
    if (capture) fileInput.setAttribute('capture', 'environment');
    if (multiple) fileInput.setAttribute('multiple', 'true');
    const listenerRef: { current: ((e: Event) => void) | null } = { current: null };
    listenerRef.current = (event: Event) => {
      const files = Array.from((event.target as HTMLInputElement).files ?? []);
      document.body.removeChild(fileInput);
      fileInput.removeEventListener('change', listenerRef.current!);
      resolve(files);
    };
    fileInput.addEventListener('change', listenerRef.current);
    document.body.append(fileInput);
    fileInput.click();
  });
}
