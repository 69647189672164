import React, {type ImgHTMLAttributes, type ReactNode} from 'react';
import {useSignedUrl} from './SignedImageCacheContext';
type Props = {name: string} & ImgHTMLAttributes<any> & FallBackComponentProps;
export type FallBackComponentProps = {
  fallback?: ReactNode;
};
export function SignedImage({name, fallback, ...imgProps}: Props) {
  const {src, error} = useSignedUrl(name);
  const [imageLoadErrored, setImageLoadErrored] = React.useState(false);
  const onImageLoadError = React.useCallback(() => {
    setImageLoadErrored(true);
  }, []);
  if (error) {
    return <span style={{color: 'red'}}>{error}</span>;
  }
  if(imageLoadErrored && fallback) {
    return fallback;
  }
  if(src === null || imageLoadErrored)
    return null;
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img onError={onImageLoadError} {...imgProps} src={src}/>;
}
