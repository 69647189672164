import {type CSSProperties} from 'react';
import WhiteLogo from './assets/images/procura-logo-small-white.png';
const darkMode = import.meta.env?.VITE_DARK_MODE === 'true';
const colors = {
    primaryTextColor: darkMode ? '#fff' : 'black',
    primary: '#3b5872',
    secondary: '#6c757d',
    gray400: '#ced4da',
    background: darkMode ? '#222222' : '#fff',
    offsetBackground: darkMode ? '#202020' : '#f9f9f9',
    largerOffsetBackground: darkMode ? '#353535' : '#f1f1f1',
    warning: '#f8c839',
    success: '#5fb173',
    danger: '#c45b65'
} as const;

export const AppTheme = {
  colors: colors,
  styles: {
    offsetBackground: !darkMode ? { background: colors.offsetBackground} : {} as CSSProperties,
    background: !darkMode ? {background: colors.background} : {} as CSSProperties,
    largerOffsetBackground: {background: colors.largerOffsetBackground} as CSSProperties,
    primaryTextColor: {color: colors.primaryTextColor} as CSSProperties
  },
  darkMode: darkMode
};

export const getLogoLink = () => WhiteLogo;

// function createLink(href: string) {
//   const link = document.createElement('link');
//   link.rel = 'stylesheet';
//   link.href = href;
//   return link;
// }

export function configureTheme() {
   document.body.classList.add('dark-mode');
   document.body.dataset['theme'] = 'dark';
  // const stdLink = document.getElementById('bootstrap-link') as HTMLLinkElement;
  // if (!darkMode) {
  //   // stdLink.href = process.env.PUBLIC_URL + '/assets/css/bootstrap/bootstrap.min.css';
  // } else {
  //
  // }
  // const themeLink = createLink(process.env.PUBLIC_URL + '/assets/css/dark-mode.scss');
  // stdLink.insertAdjacentElement('afterend', themeLink);
}
