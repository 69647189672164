import { Card, CardBody, Col, Row } from 'react-bootstrap';
import { useRmxServiceApi } from '../api';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { createDisposingObjectUrl } from '../../utils/react-query/queryClientDisposeOnCacheEvict';
import type { IActivityUpload } from '../api/portal-api';
import { useRmxServiceModalContext } from '../RmxServiceTabs/RmxServiceModalContext';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import { guessTimeZone } from '../../../util/timeZones';
import { Spinner } from '../../shared-compat/DataTable/DataTable';

interface Props {
  assetId: string | null;
  activityId: string;
  enabled: boolean;
}

export function RmxServiceLLR({ enabled, activityId, assetId }: Props) {
  const api = useRmxServiceApi();
  const { refetchUploads } = useRmxServiceModalContext();
  const [blob, setBlob] = useState<Blob | null>(null);

  const {
    data: lastLoadReportUrl,
    isError,
    isFetching
  } = useQuery({
    queryKey: ['lastLoadReport-image', assetId],
    enabled: !!assetId,
    retry: 0,
    queryFn: async () => {
      const llr = await api.getLastLoadedReport({ assetId: parseInt(assetId ?? ''), timeZone: guessTimeZone() });
      setBlob(llr);
      return createDisposingObjectUrl(llr);
    }
  });

  const {
    mutateAsync: upsertActivityUploads,
    isPending,
    isSuccess
  } = useMutation({
    mutationFn: async (req: IActivityUpload) => {
      return await api.upsertActivityUploads(req, [blob as Blob]);
    }
  });

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardBody style={{ textAlign: 'center' }}>
              {isFetching ? (
                <Spinner />
              ) : lastLoadReportUrl && assetId !== undefined ? (
                <img width='100%' src={lastLoadReportUrl.url} alt='Last Load Report' />
              ) : (
                <>Connect Asset To Procura to get Last Load Report</>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <LoadingButton
            loading={isPending}
            variant='secondary'
            disabled={
              enabled
                ? isError || isSuccess // if enabled and there is an error or llr was successfully uploaded disable the button
                : !enabled
            }
            onClick={async () => {
              const req = {
                activityId: activityId,
                title: 'Rmx Inspection'
              } satisfies IActivityUpload;

              await upsertActivityUploads(req);
              await refetchUploads.refetch();
            }}
            style={{ width: '100%', display: 'block' }}
            label='Submit'
          />
        </Col>
      </Row>
    </div>
  );
}
