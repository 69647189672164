import React, { useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FileMosaic, FullScreen, ImagePreview } from '@files-ui/react';
import { type FileMedia } from '../../rmx-service/api';
import { FileMosaicWithDownload } from '../../../components/util/files/FileMosaicWithDownload';
import { makeExtFileFromStoredFile } from '../../../components/util/files/FileDropZone';
import { type UseQueryResult } from '@tanstack/react-query';
import { getSignedFileUrl, removeFile } from '../../../api/files';
import { FileUploadButtons } from '../FileUpload/FileUploadButtons';

type CameraOrGalleryInputProps = {
  setFiles: (file: File) => void; // Updated to accept an array of files or null
  disabled?: boolean;
  getUploadMediaQuery: UseQueryResult<FileMedia, Error>;
  onFileDelete?: (id: string) => Promise<void>;
  showFileMosaicWithDownload?: boolean; // Temporary fix to prevent files from being displayed in rmx service expenses after upload, TODO remove this after autosaving expenses
  // When files are autosaved  2 files appear the first one is the one that is stored locally in state and the second one is the one that was autosaved (occurs in quotes)
  skipLocalFileUpload?: boolean;
  allowMultiFileUpload?: boolean;
  onDeleteRefresh?: () => Promise<void>; // when a file is deleted, we don't want to refetch for the same id again
  handleStateLocally?: boolean; // if true, the files state will be handled locally instead of reflecting the server state
};

export function CameraOrGalleryInput({
  showFileMosaicWithDownload,
  disabled,
  setFiles,
  getUploadMediaQuery,
  onFileDelete,
  skipLocalFileUpload,
  onDeleteRefresh,
  allowMultiFileUpload
}: CameraOrGalleryInputProps) {
  const { data: initialFiles, isLoading, refetch, isRefetching } = getUploadMediaQuery;
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [viewingFile, setViewingFile] = useState<File | string | null>();
  const storedExtFiles = useMemo(() => initialFiles?.files.map(makeExtFileFromStoredFile), [initialFiles]);
  const handleFilesChange = (file: File) => {
    setFiles(file); // Updated to handle multiple files
    skipLocalFileUpload === undefined || skipLocalFileUpload ? setFilesToUpload([...filesToUpload, file]) : setFilesToUpload([]); // Updated to handle multiple files
  };
  const [isDeleting, setIsDeleting] = useState(false);
  const hasAtLeastOneFile = filesToUpload.length > 0 || (storedExtFiles?.length ?? 0) > 0;
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
        <FileUploadButtons disabled={disabled || (hasAtLeastOneFile && !allowMultiFileUpload)} handleFileChange={handleFilesChange} />
      </div>
      {
        // filesToUpload.length > 0 && (
        <div style={{ display: 'flex', gap: '10px', flexDirection: 'row', flexWrap: 'wrap' }} className='pb-4 pt-4'>
          {
            // files to upload
            filesToUpload.map((file, index) => (
              <FileMosaic
                file={file}
                key={index}
                preview={true}
                id={file.name}
                onDelete={(fileId) => setFilesToUpload(filesToUpload.filter((f) => f.name !== fileId))}
                onSee={() => setViewingFile(file)}
              />
            ))
          }
          {isLoading || isRefetching || isDeleting ? (
            <Spinner />
          ) : (
            storedExtFiles &&
            storedExtFiles.length > 0 &&
            (showFileMosaicWithDownload === undefined || showFileMosaicWithDownload) &&
            storedExtFiles.map((storedExtFile) => (
              <FileMosaicWithDownload
                {...storedExtFile}
                downloadUrl={storedExtFile?.downloadUrl}
                key={storedExtFile?.id}
                preview={true}
                onDelete={
                  disabled
                    ? undefined
                    : async () => {
                        setIsDeleting(true);
                        onFileDelete ? await onFileDelete(storedExtFile.id as string) : await removeFile(storedExtFile.id as string);
                        await onDeleteRefresh?.();
                        setIsDeleting(false);
                        await refetch?.();
                      }
                }
                onSee={async () => {
                  const signedUrl = await getSignedFileUrl(storedExtFile.downloadUrl!);
                  setViewingFile(signedUrl);
                }}
              />
            ))
          )}
        </div>
      }
      {viewingFile && (
        <FullScreen open={true} onClose={() => setViewingFile(null)}>
          <ImagePreview src={viewingFile} />
        </FullScreen>
      )}
    </>
  );
}
