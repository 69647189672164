import {InputRow} from 'src/components/util/form-components/InputRow';
import React, {type ReactNode, useContext} from 'react';
import {EditableContext} from 'src/components/util/form-components/EditorForm/EditableContext';
import {DropdownInput, type OnSelect} from 'src/components/util/form-components/formik-inputs/DropdownInput/DropdownInput';
import {type DropdownOption} from '../../SearchableDropdown/SearchableDropdown';

type Props<T> = {
  label: string;
  name: string;
  hintText?: string | ReactNode;
  options: Array<DropdownOption<T>>;
  isClearable?: boolean;
  onSelect?: OnSelect<T>;
  showImagesInOptions?: boolean;
  defaultValue?: T;
  columnSize?: number;
  labelSize?: number;
  disabled?: boolean;
};

export function DropDownRow<T>({label, name, hintText, options, isClearable, onSelect, defaultValue, columnSize, disabled, showImagesInOptions, labelSize}: Props<T>) {
  let editable = useContext(EditableContext);
  if (disabled !== undefined) {
    editable = !disabled;
  }
  return (
    <InputRow label={label} hintText={hintText} columnSize={columnSize} labelSize={labelSize}>
      <DropdownInput
        disabled={!editable}
        dropdownData={options}
        name={name}
        showImagesInOptions={showImagesInOptions}
        isClearable={isClearable}
        onSelect={onSelect}
        defaultValue={defaultValue}
      />
    </InputRow>
  );
}
