import { type useRmxServiceApi } from '../api';
import { useDebounceRequest } from '../../../hooks/useDebounceRequest';
import { useMutation } from '@tanstack/react-query';
import type { TimeSpan } from '../../utils/timespan';
import type { QuotePart } from '../Service.types';

export function useQuoteFormAutoSave(api: ReturnType<typeof useRmxServiceApi>, refetch?: () => Promise<unknown>) {
  const { handleDebounceRequest: debounceTitleUpdate, statusInfo: quoteTitleAutoSaveStatus } = useDebounceRequest({
    fn: async (q: { activityId: string; quoteId: string | null; title: string }, signal) => {
      await api.updateQuoteTitle(q, signal);
      await refetch?.();
    },
    debounceTime: 1000
  });

  const debounceUpdateTechs = useDebounceRequest({
    fn: async (q: { activityId: string; quoteId: string | null; numberOfTechs: number | null }, signal) => {
      await api.updateQuoteNumberOfTechs(q, signal);
      await refetch?.();
    },
    debounceTime: 1000
  });

  const { handleDebounceRequest: debouncePrivateNotes, statusInfo: privateNotesAutoSaveStatus } = useDebounceRequest({
    fn: async (q: { activityId: string; quoteId: string | null; privateNotes: string }, signal) => {
      await api.updateQuotePrivateNotes(q, signal);
      await refetch?.();
    },
    debounceTime: 1000
  });

  const { mutateAsync: updateEstimatedDuration } = useMutation({
    mutationFn: async (q: { activityId: string; quoteId: string | null; estimatedDuration: TimeSpan }) => {
      await api.updateQuoteEstimatedDuration(q);
      await refetch?.();
    }
  });

  const { mutateAsync: updateConfirmedAsset } = useMutation({
    mutationFn: async (q: { activityId: string; quoteId: string | null; confirmAsset: number }) => {
      await api.updateQuoteConfirmAsset(q);
      await refetch?.();
    }
  });

  const { mutateAsync: updateIncludedParts } = useMutation({
    mutationFn: async (q: { activityId: string; quoteId: string | null; includedParts: QuotePart[] }) => {
      await api.updateQuoteIncludedParts(q);
      await refetch?.();
    }
  });

  const { mutateAsync: updateFiles } = useMutation({
    mutationFn: async (q: { activityId: string; quoteId: string | null; file: File }) => {
      await api.updateQuoteUploads({ activityId: q.activityId, quoteId: q.quoteId }, q.file);
      await refetch?.();
    }
  });

  return {
    debounceTitleUpdate,
    quoteTitleAutoSaveStatus,
    debounceUpdateTechs,
    debouncePrivateNotes,
    privateNotesAutoSaveStatus,
    updateEstimatedDuration,
    updateConfirmedAsset,
    updateIncludedParts,
    updateFiles
  };
}
