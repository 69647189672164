import React from 'react';
import { GlobalMenuModalProvider, type MenuConfig, type ShowMenu, useShowMenu } from './MenuModal/MenuModal';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { OrgLevel } from '../../api/userDashboardApi';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BootstrapMenuModal: (targetId: string, open: boolean, resource?: any, menuConfig?: MenuConfig<any>) => void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    OpenMenuModal: ShowMenu<any>;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    OpenNotesModal: (id: number, type: OrgLevel, notes: string) => void;
    roots: { [key: string]: ReactDOM.Root };
  }
}

if (!window.roots) {
  // initializing the roots object if it doesn't exist
  window.roots = {};
}
export function BootstrapMenuModal() {
  const { showMenu } = useShowMenu();
  window.OpenMenuModal = showMenu;
  return null;
}

window.BootstrapMenuModal = (targetId: string) => {
  let element = document.getElementById(targetId);
  const queryClient = new QueryClient();
  if (!element) {
    element = document.createElement('div');
    element.id = targetId;
  }
  ReactDOM.createRoot(element).render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <GlobalMenuModalProvider>
          <BootstrapMenuModal />
        </GlobalMenuModalProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};
