import {type FileMosaicProps} from '@files-ui/react/build/FileMosaic/components/file-mosaic/FileMosaicProps';
import {FileMosaic} from '@files-ui/react';
import React, {useEffect} from 'react';
import {getSignedFileUrl} from 'src/api/files';

type Props = FileMosaicProps;

export function FileMosaicWithDownload(props: Props) {
  const {downloadUrl, ...rest} = props;
  const [actualDownloadUrl, setActualDownloadUrl] = React.useState<string | null>(null);
  useEffect(() => {
    if(downloadUrl) {
      async function downloadSignedUrl() {
         setActualDownloadUrl(await getSignedFileUrl(downloadUrl!));
      }
      void downloadSignedUrl();
    }
  }, [downloadUrl]);
  return <FileMosaic {...rest} downloadUrl={actualDownloadUrl ?? undefined}/>;
}
