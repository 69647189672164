import React from 'react';
import {makeUrl} from 'src/common/util';
import {type BreadCrumbData} from 'src/api/userDashboardApi';
import {DashboardPaths} from 'src/pages/Dashboard/DashboardRoutePaths';
import s from 'src/pages/Dashboard/DashboardBreadCrumbs/DashboardBreadCrumbs.module.scss';
import clsx from 'clsx';
import { useApplicationType } from '../../../ApplicationTypeContext';
import { useNavigationLink } from '../../../router/UseNavigationLink';

type Props = {
  breadCrumbs: BreadCrumbData[];
  className?: string;
};

export type IBreadCrumb = {
  href?: string;
  label: string;
  disabled: boolean;
};

export function useGetBreadCrumbs() {
  const appType = useApplicationType();
  if(appType === 'Portal')
    return getPortalBreadCrumbs;
  return getBreadCrumbs;
}
function getBreadCrumbs(p: BreadCrumbData[]): IBreadCrumb[]  {
  const arr: IBreadCrumb[] = [];
  arr.push(...p.map<IBreadCrumb>(b => makeBreadCrumbFromData(b)));
  if(arr.length > 1)
    arr[arr.length - 1].href = undefined;
  return arr;
}

function getPortalBreadCrumbs(p: BreadCrumbData[]): IBreadCrumb[] {
  const arr: IBreadCrumb[] = [];
  arr.push(...p.map<IBreadCrumb>(b => makePortalBreadCrumbFromData(b)));
  if(arr.length > 1)
    arr[arr.length - 1].href = undefined;
  return arr;
}


export function makeBreadCrumbFromData(p: BreadCrumbData): IBreadCrumb {
  return ({label: p.label, href: getUrlPathByType(p), disabled: p.disabled});
}
export function makePortalBreadCrumbFromData(p: BreadCrumbData): IBreadCrumb {
  return ({label: p.label, href: getPortalUrlPathByType(p), disabled: p.disabled});
}

export function getPreviousBreadCrumb<T extends BreadCrumbData>(b: T[]): T {
  if(b.length === 0)
    throw new Error('No previous bread crumb');
  if(b.length >= 2) {
    const segments = b.slice(0, b.length - 1).reverse();
    for(const segment of segments) {
      if(!segment.disabled)
        return segment;
    }
  }
  return b[0];
}

export function getPreviousBreadCrumbLink(b?: BreadCrumbData[]): string|undefined {
  if(b === undefined)
    return undefined;
  if(b.length <= 1)
    return undefined;
  return getUrlPathByType(getPreviousBreadCrumb(b));
}

export function getPortalPreviousBreadCrumbLink(b?: BreadCrumbData[]): string|undefined {
  if(b === undefined)
    return undefined;
  if(b.length <= 1)
    return undefined;
  return getPortalUrlPathByType(getPreviousBreadCrumb(b));
}

export function useGetPreviousBreadCrumbLink() {
  const appType = useApplicationType();
  if(appType === 'Portal')
    return getPortalPreviousBreadCrumbLink;
  return getPreviousBreadCrumbLink;
}

export function getUrlPathByType(b: BreadCrumbData) {
  return makeUrl(getUrlBasePathByType(b), {id: b.id, name: b.label});
}

export function getPortalUrlPathByType(b: BreadCrumbData) {
  return makeUrl(getPortalUrlBasePathByType(b), {id: b.id, name: b.label});
}

export function getUrlBasePathByType(b: BreadCrumbData) {
  const t = b.type;
  switch (t) {
    case 'Company': return DashboardPaths.CompanyDashboard;
    case 'Site': return DashboardPaths.SiteDashboard;
    case 'Installation': return DashboardPaths.InstallationDashboard;
    case 'SystemProcess': return DashboardPaths.SystemProcessDashboard;
    case 'Asset': return DashboardPaths.AssetDetails;
    case 'Global': return DashboardPaths.Dashboard;
    default: throw new Error('Unknown BreadCrumbDataType: ' + t);
  }
}

export function getPortalUrlBasePathByType(b: BreadCrumbData) {
  const t = b.type;
  switch (t) {
    case 'Company': return `/?p=SiteTiles&pid=:id`;
    case 'Site': return `?p=InstallationTiles&pid=:id`;
    case 'Installation': return `/?p=InstallationPage&pid=:id`;
    case 'SystemProcess': return `/?p=InstallationPage&pid=:id`;
    case 'Asset': return `/?p=AssetPage&pid=:id`;
    case 'Global': return '/';
    default: throw new Error('Unknown BreadCrumbDataType: ' + t);
  }
}

export function DashboardBreadCrumbs({breadCrumbs: breadCrumbsParam, className}: Props) {
  const getBreadCrumbs = useGetBreadCrumbs();
  if(breadCrumbsParam.length === 0)
    return null;
  const breadCrumbs = getBreadCrumbs(breadCrumbsParam);
  return <div className={clsx(s['bread-crumbs'], className)}>
    {breadCrumbs.map((b, i) => <React.Fragment key={b.href ?? ''}>
      <BreadCrumb breadCrumb={b}  />
      {i !== breadCrumbs.length -1 && <BreadCrumbSeparator />}
    </React.Fragment>)}
  </div>;
}

type BreadCrumbProps = {
  breadCrumb: IBreadCrumb;
};

function BreadCrumb({breadCrumb: {href, label, disabled}}: BreadCrumbProps) {
  const Link = useNavigationLink();
  return href && !disabled
    ? <Link className={s['breadcrumb']} to={href}>{label}</Link>
    : <span className={s['breadcrumb']}>{label}</span>;
}

function BreadCrumbSeparator(_: {}) {
  return <span className={s['separator']}>{'>'}</span>;
}
