import { type Activity, getAssetDetailsLabel } from '../Service.types';
import React from 'react';
import { NavLink } from 'react-bootstrap';
import { createServerColumnHelper } from '../../shared-compat/DataTable/hooks';
import { getMapUrl } from '../../utils';

const helper = createServerColumnHelper<Activity>();
const ch = helper.accessor;

export const activityColumnsDef = (enableAssetColumnFilter?: boolean, enableSiteColumnFilter?: boolean) =>
  [
    ch('status', 'string', {
      header: 'Status',
      enableColumnFilter: true,
      cell: (info) => {
        if (info.row.original.status === 'In Progress') {
          return <strong style={{ fontWeight: 700 }}> {info.getValue()} </strong>;
        }
        return info.getValue();
      },
      footer: (props) => props.column.id,
      meta: {
        serverFilters: ['exact-text']
      }
    }),
    ch('activityCrmId', 'string', {
      meta: { serverFilters: ['exact-text'] },
      header: 'Activity Id',
      cell: (info) => {
        return info.getValue();
      },
      footer: (props) => props.column.id,
      enableColumnFilter: true
    }),
    ch('workOrderId', 'number', {
      cell: (info) => info.getValue(),
      header: () => <span>Work Order Id</span>,
      footer: (props) => props.column.id,
      enableColumnFilter: true
    }),
    ch('customerName', 'string', {
      header: 'Customer',
      footer: (props) => props.column.id,
      enableSorting: true,
      sortDescFirst: false,
      cell: (info) => {
        const customerName = info.row.original.customerName;
        const customerAddress = info.row.original.customerAddress;
        const mapUrl = getMapUrl(customerAddress);

        return (
          <div>
            {customerName}
            <NavLink href={mapUrl} target='_blank' style={{ padding: '0px', textDecoration: 'underline' }}>
              {customerAddress}
            </NavLink>
          </div>
        );
      },
      enableColumnFilter: enableSiteColumnFilter ?? true
    }),
    ch('startDate', 'date', {
      header: 'Start Date',
      footer: (props) => props.column.id,
      enableSorting: true,
      sortDescFirst: false,
      enableColumnFilter: true,
      cell: (info) => {
        const startDate = info.getValue() as Date;
        return startDate.toLocaleDateString();
      } // formatActivityStart(info.row.original)
    }),
    ch('onSiteTime', 'time', {
      header: 'On Site Time',
      meta: { serverFilters: ['exact-time'] },
      cell: (info) => {
        const onSiteTime = info.getValue() as Date;
        const timeOptions: Intl.DateTimeFormatOptions = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true // or false for 24-hour clock
        };
        return onSiteTime.toLocaleTimeString(undefined, timeOptions);
      },
      footer: (props) => props.column.id
    }),
    ch('assetName', 'string', {
      header: 'Asset',
      footer: (props) => props.column.id,
      cell: (info) => {
        const row = info.row.original;
        const location = row.assetLocation ?? '';
        const description = getAssetDetailsLabel({
          id: row.assetId,
          assetCrmId: row.assetCrmId,
          crmType: row.assetCrmType,
          crmType2: row.assetCrmType2,
          assetManufacturer: row.assetManufacturer,
          modelNumber: row.assetModel,
          serialNumber: row.assetSerial,
          location: row.assetLocation,
          assetName: row.assetName,
          assetGroup: row.assetGroup
        });
        if (location) {
          return (
            <div>
              {description}
              <div>
                <b>{location}</b>
              </div>
            </div>
          );
        }
        return description;
      },
      enableColumnFilter: enableAssetColumnFilter ?? true
    }),
    ch('assignedToName', 'string', {
      header: 'Assigned To',
      footer: (props) => props.column.id
    }),
    ch('description', 'string', {
      header: 'Activity Description',
      footer: (props) => props.column.id,
      enableColumnFilter: true,
      cell: (info) => {
        const description = info.getValue();
        const maxLength = 50;
        const trimmedDescription = description && description.length > maxLength ? `${description.substring(0, maxLength)}...` : description;

        return <span>{trimmedDescription}</span>;
      }
    }),
    ch('activityType', 'string', {
      header: 'Activity Type',
      footer: (props) => props.column.id
    }),
    ch('availability', 'string', {
      header: 'Work Order Type',
      footer: (props) => props.column.id
    })
  ] as const;
