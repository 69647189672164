/* eslint-disable @typescript-eslint/naming-convention */
export const RmxBoxPaths = {
  New: (baseUrl: string) => baseUrl +  '/rmx-box/new',
  Edit: (baseUrl: string) => baseUrl +  '/rmx-box/edit/:id',
  View: (baseUrl: string) => baseUrl +  '/rmx-box/view/:id'
};

export const RmxBoxPortSettingsPaths = {
  Edit: (baseUrl: string) => baseUrl + '/rmx-box/edit/:rmxBoxId/:id',
  View: (baseUrl: string) => baseUrl + '/rmx-box/view/:rmxBoxId/:id'
};
export const RmxBoxSourcePaths = {
  Edit: (baseUrl: string) => baseUrl + '/rmx-box/edit/:rmxBoxId/:portId/:id',
  View: (baseUrl: string) => baseUrl + '/rmx-box/view/:rmxBoxId/:portId/:id',
  New: (baseUrl: string) => baseUrl + '/rmx-box/:rmxBoxId/:portId/new'
};
