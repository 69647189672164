import type { Activity } from '../Service.types';
import { DataTable } from '../../shared-compat/DataTable/DataTable';
import React, { type ReactElement, useEffect, useMemo, useState } from 'react';
import { type BetterColumnSort, useServerDataTable } from '../../shared-compat/DataTable/hooks';
import { useModal } from '../../hooks/useModal';
import { RmxServiceActivityModal, type RmxServiceActivityModalParam } from '../RmxServiceTabs/RmxServiceActivityModal';
import { activityColumnsDef } from '../RmxServiceTableDefinitions/ActivityTableDefinition';
import { useRmxServiceApi } from '../api';
import { redirectToAsset } from '../RmxServiceTabs/redirectToAsset';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { QuoteTable } from '../QuoteTable/QuoteTable';

import { hasRole } from '../../utils';
import { DayFilter } from '../../utils/DayFilter/DayFilter';
import { MyFlagTable } from '../MyFlagTable/MyFlagTable';
import { RmxCreateFlag } from '../Flags/RmxCreateFlag';

export function useActivityTable(filterByAssetId?: number, filterBySiteId?: number, initialSort?: BetterColumnSort<Activity>[]) {
  const api = useRmxServiceApi();

  const activityColumns = useMemo(() => activityColumnsDef(!filterByAssetId, !filterBySiteId), [filterByAssetId, filterBySiteId]);

  const activityTable = useServerDataTable({
    queryKey: ['activities'],
    columns: activityColumns,
    getRows: async (params) =>
      await api.getActivities({
        ...params,
        assetId: filterByAssetId ?? null,
        siteId: filterBySiteId ?? null,
        daysFilter: serviceDateFilter,
        myWork: false,
        statusFilter: null
      }),
    initialSorting: initialSort
  });
  const activityModal = useModal<RmxServiceActivityModalParam>({
    onClose: async () => {
      await activityTable!.refetch();
    }
  });
  const [serviceDateFilter, setServiceDateFilter] = useState<number | null | string>(null);
  return { activityTable, activityModal, serviceDateFilter, setServiceDateFilter };
}

interface ActivityDataTable {
  isMyWork: boolean;
  hideEllipsisButton?: boolean;
  header?: ReactElement;
  footer?: ReactElement;
  disableEditingActivityDetails?: (row: Activity) => boolean;
  refetchFlagTable?: () => void;
  filterByAssetId?: number;
  filterBySiteId?: number;
}

export function ActivityTable(props: ActivityDataTable) {
  const initialStatus = [
    { id: 'status', desc: false },
    // Filter By SiteId or AssetId is passed in to get history of a specific site or asset, business has deemed that when viewing history,
    // onsite time & start date should be sorted desc
    { id: 'startDate', desc: !!props.filterBySiteId || !!props.filterByAssetId },
    { id: 'onSiteTime', desc: !!props.filterBySiteId || !!props.filterByAssetId },
    { id: 'assignedToName', desc: false }
  ] satisfies BetterColumnSort<Activity>[];

  const { activityTable, activityModal, serviceDateFilter, setServiceDateFilter } = useActivityTable(
    props.filterByAssetId,
    props.filterBySiteId,
    initialStatus
  );

  const assetQuoteHistoryModal = useModal<{ assetId: number }>();
  const assetHistoryModal = useModal<{ assetId: number }>();
  const flagHistoryModal = useModal<{ activityId: string }>();
  const createFlagModal = useModal<{ activityId?: string; customerId?: number; siteName?: string }>();

  useEffect(
    () => {
      // business requirement: when the status filter is set to 'Archived', sort by startDate desc and onSiteTime asc
      if (activityTable.columnFilters.find((filter) => filter.id === 'status' && filter.value === 'Archived')) {
        const newSorting = [
          { id: 'startDate', desc: true },
          { id: 'onSiteTime', desc: false }
        ] satisfies BetterColumnSort<Activity>[];
        activityTable.setSorting(newSorting); // Dynamically update the table sorting
      }
      // business requirement: when column filter changes reset to initial sorting
      else if (activityTable.columnFilters) {
        // resetting initial sorting when column filters change
        activityTable.setSorting(initialStatus);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activityTable.columnFilters]
  );

  return (
    <>
      <DataTable
        negativeMarginsOnMobile={true}
        table={activityTable}
        header={props.header ?? undefined}
        footer={props?.footer ?? <DayFilter value={serviceDateFilter} setGlobalDateFilter={setServiceDateFilter} />}
        onRowClick={(row: Activity) => {
          void activityModal.open({
            resource: row,
            disableEditingActivityDetails: props.disableEditingActivityDetails?.(row) ?? (row.status !== 'In Progress' || hasRole('Technician')),
            enableTechEditing: props.isMyWork
          });
        }}
      />
      {activityModal.isOpen && (
        <RmxServiceActivityModal
          state={activityModal}
          enableTechEditing={props.isMyWork}
          createFlag={(a) => createFlagModal.open({ activityId: a.id, customerId: a.customerId })}
          openAsset={async (a) => await redirectToAsset(a.assetId, true)}
          openAssetHistory={async (a) => {
            await assetHistoryModal.open({ assetId: a.assetId });
          }}
          openQuoteHistory={async (a) => await assetQuoteHistoryModal.open({ assetId: a.assetId })}
          openFlagHistory={async (a) => await flagHistoryModal.open({ activityId: a.id })}
          hideEllipsisButton={props.hideEllipsisButton}
        />
      )}

      {createFlagModal.isOpen && <RmxCreateFlag state={createFlagModal} />}
      <GenericModal state={assetQuoteHistoryModal} fullScreen={true} label={`Quote History`} showClose={true}>
        <QuoteTable filterByAssetId={assetQuoteHistoryModal.param?.assetId} />
      </GenericModal>
      <GenericModal state={assetHistoryModal} fullScreen={true} label={`Asset History`} showClose={true}>
        <ActivityTable isMyWork={false} hideEllipsisButton={true} filterByAssetId={assetHistoryModal.param?.assetId} filterBySiteId={props.filterBySiteId} />
      </GenericModal>
      <GenericModal fullScreen={true} state={flagHistoryModal} label={'Flag History'} showClose={true}>
        <MyFlagTable activityId={flagHistoryModal.param?.activityId} hideNewFlagButton={true} />
      </GenericModal>
    </>
  );
}
