import IconButton from '../IconButton/IconButton';
import React from 'react';
import s from './HeaderButton.module.scss';
import {type IconProp} from '@fortawesome/fontawesome-svg-core';
type Props = {
  link: string;
  icon: IconProp;
  tooltip: string;
  newTab?: boolean;
};
export function HeaderButton({link, tooltip, icon, newTab}: Props) {
  return (
    <IconButton newTab={newTab} icon={icon} iconToolTipText={tooltip} link={link} className={s['header-button']} color='#212529' linkClassName={s['link']} />
  );
}

type HeaderBackButtonProps = {
  link: string;
};

export function HeaderBackButton({link}: HeaderBackButtonProps) {
  return (
    <HeaderButton icon='arrow-left' tooltip='Back' link={link} />
  );
}
