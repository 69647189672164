/* eslint-disable */
import { ActionConfig } from '../ActionModal/ActionModal';
import { MenuConfig } from './MenuModal';
import { getAssetUuidFromAssetId } from '../../portal-api';
import { redirectToAsset } from '../../rmx-service/RmxServiceTabs/redirectToAsset';
import { sanitizeUrl } from '../../../common/util';
import { openBypassModal } from '../../utils/BypassModal/BypassModal';
import { openServiceModal } from '../../rmx-service/RmxServiceComponent/RmxServiceComponentModal';
import { getLegacyPortalApi } from '../../legacy-portal-api/legacy-api';
import { getPortalServiceApi } from '../../rmx-service/api/portal-api';
import { type PortalOrgLevel } from 'src/portal/utils/Legacy';

declare global {
  interface Window {
    companyName: string;
    siteName: string;
    installationName: string;
  }
}

/**
 * This File contains the indicator menu modal for installation page and menu modal for asset tiles
 * initialized in the InstallationPage
 */

//@ts-ignore
window.initializeInstallationMenuModal = () => {
  const url = new URL(window.location.href);
  const companyId = window.PageParams.CompanyId;
  const siteId = window.PageParams.SiteId;
  const installationName = window.PageParams.InstallationName;
  const legacyApi = getLegacyPortalApi();
  // @ts-ignore
  window.OpenIndicatorMenuModal = async function (id: string, type: string) {
    // @ts-ignore
    const siteName = window.siteName;
    const rmxApi = getPortalServiceApi();
    const syncedUser = await rmxApi.syncedUser();
    const menuConfig: MenuConfig<any> = {
      // open: () => {
      //     //open should not be needed for sites
      //    window.location.href =`${window.origin}?p=SiteTiles&pid=${id}`; // IF there are no other sites then this redirects to the Company tiles page
      // },
      // action: () => {}, // empty action handler used to show the action button
      flag: (siteName) => {},
      alerts: () => (window.location.href = `${url.origin}?p=Alerts&c=${companyId}&s=${siteId}&i=${encodeURIComponent(installationName!)}`),
      // history: () => {}, // coming soon
      graphing: () => {
        window.location.href = `${window.origin}?p=ChartingPrefill&sId=${siteId}`;
      },

      service: async () => {
        const rmxSiteId = await legacyApi.getSiteIntIdByGuid(id);
        openServiceModal({ siteId: rmxSiteId });
      },
      // rmx_service: async () => {
      //   const rmxSiteId = await getSiteIntIdByGuid(id);
      //   window.location.href = `${url.origin}?p=RmxService&s=${rmxSiteId}`;
      // },
      reports: () => {
        window.location.href = `${window.origin}?p=SystemAssessment&cId=${companyId}&sId=${siteId}`;
      },
      notification_setup: () => {
        window.location.href = sanitizeUrl`/?p=AlertsSetup&c=${window.companyName}&s=${window.siteName}&i=${installationName!}`;
      },

      // @ts-ignore
      getLabel: () => window.siteName,
      // view_requests: () => {
      //   // view requests
      //   // @ts-ignore
      //   openViewRequestsDialog(id, type);
      // },
      // request: () => {
      //   // @ts-ignore
      //   openSubmitRequestDialogFor(id, type);
      // },
      bypass: async () => {
        // // @ts-ignore
        // window.orgId = id;
        // // @ts-ignore
        // window.orgType = type;
        // // @ts-ignore
        // window.AddByPass(window.siteName);

        // const rmxSiteId = await getSiteIntIdByGuid(id);
        openBypassModal({ id: id, siteName: siteName!, type: 'Site', syncedUser });
      }
    };

    const actionConfig: ActionConfig<any> = {
      // view_requests: () => {
      //   // view requests
      //   // @ts-ignore
      //   openViewRequestsDialog(id, type);
      // },
      // submit_requests: () => {
      //   // submit request
      //   // @ts-ignore
      //   openSubmitRequestDialogFor(id, type);
      // },
      // change: () => {},
      // snooze: () => {}, // coming soon
      bypass_add: () => {
        // @ts-ignore
        AddByPass();
      },
      bypass_clear: () => {
        // @ts-ignore
        window['siteID'] = id;
        // @ts-ignore
        window['assetID'] = undefined;
        // @ts-ignore
        window.ClearByPass();
      }
      // edit: () => {openNotesDialog(id, type)},
    };
    if (!window.User.IsRmxServiceEnabled) {
      delete menuConfig.service;
      delete menuConfig.flag;
    }

    // @ts-ignore
    window.OpenMenuModal(window.siteName, menuConfig, actionConfig);
  };

  async function OpenAssetModalMenu(assetId: number, type: PortalOrgLevel, assetName: string, notes?: string) {
    //@ts-ignore
    window.orgId = assetId;
    const rmxApi = getPortalServiceApi();
    const syncedUser = await rmxApi.syncedUser();
    //@ts-ignore
    window.orgType = type;
    const assetUuid = await getAssetUuidFromAssetId(assetId);
    //@ts-ignore
    const siteName = `${window.siteName}`;

    const assetDetails = await legacyApi.getAssetDetails(assetId);
    // these are required to be defined or else bypasses will not work
    const menuConfig: MenuConfig<any> = {
      open: () => {
        redirectToAsset(assetId);
      },

      flag: () => {},
      // action: () => {}, // empty action handler used to show the action button

      alerts: () => {
        // @ts-ignore
        window.ViewAlertDetails(assetId, type);
      },
      // history: () => {}, // coming soon
      // view_requests: () => {
      //   // view requests
      //   // @ts-ignore
      //   openViewRequestsDialog(id, type);
      // },
      // request: () => {
      //   // @ts-ignore
      //   openSubmitRequestDialogFor(id, type);
      // },
      service: () => {
        openServiceModal({ assetId });
      },
      bypass: async () => {
        // @ts-ignore
        openBypassModal({
          id: assetId,
          siteName: siteName!,
          type: 'Asset',
          assetModel: assetDetails.AssetModel,
          assetSerial: assetDetails.AssetSerial,
          syncedUser
        });
      },
      reports: () => {
        window.location.href = `${window.origin}?p=SystemAssessment&cId=${companyId}&sId=${siteId}`;
      },
      // rmx_service: () => {
      //   window.location.href = `${url.origin}?p=RmxService&a=${id}`;
      // },
      documents: () => {
        window.location.href = `${window.origin}/?p=Uploads&pid=${assetUuid}`;
      },
      graphing: () => {
        window.location.href = `${window.origin}/?p=ChartingPrefill&aId=${assetUuid}`;
      },
      getLabel: () => assetName,
      edit: async () => {
        const rmxId = await legacyApi.getIdByGuidAndType(assetUuid, type);
        window.OpenNotesModal(rmxId, type, notes ?? '');
      }
    };
    const actionConfig: ActionConfig<any> = {
      // view_requests: () => {
      //   // view requests
      //   //@ts-ignore
      //   openViewRequestsDialog(id, type);
      // },
      // submit_requests: () => {
      //   // submit request
      //   //@ts-ignore
      //   openSubmitRequestDialogFor(id, type);
      // },
      // change: () => {}, // coming soon
      // snooze: () => {}, // coming soon
      bypass_add: () => {
        //@ts-ignore
        window.AddByPass(assetName);
      },
      bypass_clear: () => {
        // @ts-ignore
        window['assetID'] = assetId;

        // @ts-ignore
        window.ClearByPass();
      },
      edit: () => {
        // @ts-ignore
        openNotesDialog(assetId, type);
      }
    };

    if (!window.User.IsRmxServiceEnabled) {
      delete menuConfig.service;
      delete menuConfig.flag;
    }

    const siteAssetName = `${siteName} : ${assetName}`;
    window.OpenMenuModal(siteAssetName, menuConfig, actionConfig); // passing in the siteName into the resource to be able to use it with creating flags ;
  }

  // @ts-ignore
  window.ShowActionModal = async function (assetId: number, notes?: string) {
    // const assetID = id;
    // const orgId = id;
    const assetName = await legacyApi.GetAssetName(assetId); // this updates modal_title which we then access in menu modal to get asset name
    await OpenAssetModalMenu(assetId, 'Asset', assetName, notes);
  };
};
