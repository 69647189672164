import { Alert, Button, Card, CardBody } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import s from './RmxServiceForms.module.scss';
import { Spinner } from '../../utils/Spinner/Spinner';
import { type Activity } from '../Service.types';
import { Icon } from '../../status-indicator/Icon/Icon';
import { faCheck, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteFormSubmission, type FormSchemaModel, getLatestFormSchemas, type SubmissionInfo } from '../../form/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddFormModal } from './AddFormModal';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import { useFormData } from './FormDataContext';
import { useConfirmationDialog } from '../../utils/GlobalConfirmDialog';
import { getMapValues } from '../../../common/util';
import { useRmxServiceModalContext } from '../RmxServiceTabs/RmxServiceModalContext';
import { FormModal } from './FormModal';
import { useModal } from '../../hooks/useModal';

interface Props {
  activity: Activity;
  enabled: boolean;
  pickedAllParts: boolean;
  // confirmedContact: boolean;
}

export function isSubmissionAndSchemaInfo(form: any): form is SubmissionInfo {
  return 'formSchemaRevisionId' in form && 'data' in form;
}

// export type FormIFrameRef = RefObject<HTMLIFrameElement>;
export function RmxServiceForms({ enabled, activity, pickedAllParts }: Props) {
  const { nonRequiredForms, requiredForms, formSubmissionQuery } = useFormData();
  const refetchFormSubmission = formSubmissionQuery.refetch;

  const { refetchIsSlamCompleted } = useRmxServiceModalContext();

  const { data: latestFormSchemas, isFetching } = useQuery({
    queryKey: ['form-schema', activity.id],
    queryFn: async () => {
      return await getLatestFormSchemas();
    }
  });

  const slamRevisionId = useFormData().formTitleValueMap?.get('slam')?.formSchemaRevisionId;
  const formViewerModal = useModal<FormSchemaModel | SubmissionInfo, FormSchemaModel | SubmissionInfo>({
      onClose: async (resultingForm) => {
        // narrow down the type of resultingForm to SubmissionAndSchemaInfo to access the data property
        if (isSubmissionAndSchemaInfo(resultingForm)) {
          if (resultingForm?.formSchemaRevisionId === slamRevisionId) {
            if (resultingForm.data?.submit) {
              await refetchIsSlamCompleted();
            }
          }
        }
        await refetchFormSubmission();
        setOpenAddForm(false);
      }
    }),
    { open: openFormViewer } = formViewerModal;
  const [openAddForm, setOpenAddForm] = useState(false);
  const formMenuHeaderRef = useRef<HTMLDivElement>(null);

  const { mutateAsync: deleteSubmission, isPending: isPendingDelete } = useMutation({
    mutationFn: async (id: number) => {
      await deleteFormSubmission(id);
    }
  });

  const { confirm } = useConfirmationDialog();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      <Card style={{ width: '100%' }}>
        <Card.Header style={{ textAlign: 'center' }}>
          <div ref={formMenuHeaderRef}>Forms</div>
        </Card.Header>
        <CardBody className={s['main-card-body']}>
          {formSubmissionQuery.isError && <Alert variant='danger'>Error Fetching Form Submissions, data may be outdated</Alert>}
          <Card style={{ width: '100%' }}>
            <Card.Header>
              <div style={{ minHeight: '20px' }}>Required Forms</div>
            </Card.Header>
            <Card.Body>
              {isFetching || formSubmissionQuery.isRefetching ? (
                <Spinner />
              ) : requiredForms && requiredForms.size !== 0 ? (
                Array.from(requiredForms).map(([title, form], index) => {
                  return (
                    <div key={index} style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
                        <Button
                          // disabled={title === 'slam' && !confirmedContact && !form.data?.submit}
                          style={{ display: 'block', width: '100%' }}
                          variant={'secondary'}
                          onClick={() => {
                            if (title === 'slam' && !pickedAllParts) {
                              alert('You must pick all parts before doing slam');
                            } else {
                              void openFormViewer(form);
                            }
                          }}
                        >
                          {title.toUpperCase()}
                          {form.data?.submit && <Icon className={s['success-icon']} icon={faCheck} />}
                        </Button>
                      </div>
                      {title === 'slam' && enabled && form.data !== null && (
                        <LoadingButton
                          variant='outline-secondary'
                          disabled={!enabled} // form.data?.submit
                          loading={isPendingDelete}
                          onClick={async () => {
                            await confirm({
                              title: 'Delete Form',
                              message: 'Are you sure you want to clear your SLAM?',
                              positiveText: 'Yes',
                              negativeText: 'No',
                              onConfirm: async () => {
                                await deleteSubmission(form.id!);
                                await formSubmissionQuery.refetch();
                              }
                            });
                          }}
                          label={<FontAwesomeIcon color={'red'} icon={faTrash} />}
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <div className={s['card']}>No Required Forms For This Activity</div>
              )}
            </Card.Body>
          </Card>
          <Card style={{ width: '100%' }}>
            <Card.Header>
              {
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  Added Forms
                  <Button
                    size='sm'
                    onClick={() => setOpenAddForm(true)}
                    style={{
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '0',
                      backgroundColor: 'transparent',
                      borderColor: 'transparent'
                    }}
                    variant='outline-secondary'
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </Button>
                </div>
              }
            </Card.Header>

            <Card.Body style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {isFetching || formSubmissionQuery.isRefetching ? (
                <Spinner />
              ) : nonRequiredForms && nonRequiredForms.size !== 0 ? (
                Array.from(nonRequiredForms).map(([title, form], index) => {
                  return (
                    <div key={index} style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', width: '100%', position: 'relative' }}>
                        <Button
                          onClick={() => {
                            void openFormViewer(form);
                          }}
                          style={{ display: 'block', width: '100%' }}
                          variant={'secondary'}
                        >
                          {title.toUpperCase()}
                          {form.data?.submit && <Icon className={s['success-icon']} icon={faCheck} />}
                        </Button>
                      </div>
                      {
                        <LoadingButton
                          variant='outline-secondary'
                          disabled={!enabled} // form.data?.submit
                          loading={isPendingDelete}
                          onClick={async () => {
                            await confirm({
                              title: 'Delete Form',
                              message: 'Are you sure you want to delete this form?',
                              positiveText: 'Yes',
                              negativeText: 'No',
                              onConfirm: async () => {
                                await deleteSubmission(form.id);
                                await formSubmissionQuery.refetch();
                              }
                            });
                          }}
                          label={<FontAwesomeIcon color={'red'} icon={faTrash} />}
                        />
                      }
                    </div>
                  );
                })
              ) : (
                <div className={s['card']}>No Forms Added</div>
              )}
            </Card.Body>
          </Card>
          <FormModal
            activityId={activity.id}
            enabled={enabled}
            modal={formViewerModal}
            pdfTitle={`${formViewerModal.param?.formName ?? 'form'}-${activity.assignedToName}`}
          />
          <AddFormModal
            latestFormSchemas={latestFormSchemas ?? []}
            nonRequiredForms={getMapValues(nonRequiredForms ?? new Map())}
            open={openAddForm}
            setIsOpen={setOpenAddForm}
            onFormClick={(form) => {
              void openFormViewer(form);
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
}
