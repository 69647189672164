export const EParentResourceType = {
  'ControllerType': 'ControllerType',
  'ActivityUploadType': 'ActivityUploadType',
  'ActivityExpenseType': 'ActivityExpenseType',
  'ActivityQuoteType': 'ActivityQuoteType'
} as const;

export type ParentResourceType = typeof EParentResourceType[keyof typeof EParentResourceType];


export type FileParentResource = {
  parentResourceId: number;
  parentResourceType: ParentResourceType;
};
