import ReactDOM from 'react-dom/client';
import React, { StrictMode } from 'react';
import { SiteEditorModal } from './SiteEditorModal';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

declare global {
  interface Window {
    initConfigurationPage: (target: string) => void;
    openSiteEditor: (companyUuid: string, editMode: boolean) => void;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    GetCompaniesGridListAll: () => void;
  }
}
const queryClient = new QueryClient();
window.initConfigurationPage = function (target: string) {
  const root = ReactDOM.createRoot(document.getElementById(target) as HTMLElement);
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ConfigurationPageWrapper />
      </QueryClientProvider>
    </StrictMode>
  );
};

export function ConfigurationPageWrapper() {
  return <SiteEditorModal />;
}
