/*eslint-disable */
import type { AssetDetails } from '../menu-modal/MenuModal/portal-asset-context';
import type { SiteFormValues } from '../configuration/SiteEditorModal';
import { createPortalAxios } from '../utils/ajax';
import type { AxiosInstance } from 'axios';

export type LocationDataResponse = {
  Countries: string[];
  StatesByCountry: Record<string, string[] | undefined>;
};
export type PortalSiteSelectionList = {
  uuid: string;
  name: string;
};

export type SiteInfo = {
  name: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  postalcode: string;
  crmCustomerId: string | null;
};

export type SiteDropDownOptionListResponse = { siteName: string; siteUuid: string }[];

class LegacyPortalApi {
  private portalAxios: AxiosInstance = createPortalAxios();
  // constructor(private api: AxiosInstance) {}

  async addSite(companyUuid: string, siteName: string) {
    const result = (
      await this.portalAxios.post<{ addsuccessful: boolean; message: string; siteid: string }>('/Location/AddSite', {
        CompanyGuid: companyUuid,
        SiteName: siteName
      })
    ).data;
    if (result.addsuccessful) {
      return result.siteid;
    } else {
      throw result.message;
    }
  }

  async editSite(companyUuid: string, siteUuid: string, formValues: SiteFormValues) {
    const payload = {
      CompanyID: companyUuid,
      OldSiteGuid: siteUuid,
      NewSiteName: formValues.name,
      Address1: formValues.address1,
      Address2: formValues.address2,
      City: formValues.city,
      Country: formValues.country,
      State: formValues.stateProvince,
      PostalCode: formValues.postalCode,
      CrmCustomerId: formValues.crmCustomerId
    };
    const oData = (await this.portalAxios.post<{ updatesuccessful: boolean; message: string }>('/Location/EditSite', payload)).data;
    if (oData.updatesuccessful) {
      return oData;
    } else {
      throw oData.message;
    }
  }

  async upsertSite(companyUuid: string, siteUuid: string | null, formValues: SiteFormValues) {
    const payload = {
      CompanyID: companyUuid,
      OldSiteGuid: siteUuid,
      NewSiteName: formValues.name,
      Address1: formValues.address1,
      Address2: formValues.address2,
      City: formValues.city,
      Country: formValues.country,
      State: formValues.stateProvince,
      PostalCode: formValues.postalCode,
      CrmCustomerId: formValues.crmCustomerId
    };
    const oData = (await this.portalAxios.post<{ updatesuccessful: boolean; message: string }>('/Location/UpsertSite', payload)).data;
    if (oData.updatesuccessful) {
      return oData;
    } else {
      throw oData.message;
    }
  }
  getSiteInfo(siteUuid: string) {
    return new Promise<SiteInfo>((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/Location/GetSiteInfo',
        cache: false,
        data: {
          SiteID: siteUuid
        },
        success: function (oData) {
          resolve(oData);
        },
        error: function (result) {
          reject(result);
        }
      });
    });
  }

  getLocationData() {
    return new Promise<LocationDataResponse>((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/Location/GetLocationData',
        cache: false,
        success: function (oData) {
          resolve(oData);
        },
        error: function (result) {
          reject(result);
        }
      });
    });
  }

  async getSiteList(companyUuid: string) {
    return (
      await this.portalAxios.post<SiteDropDownOptionListResponse>('/Location/GetSiteDataList', {
        companyUuid: companyUuid
      })
    ).data;
  }

  GetAssetName(id: number): Promise<string> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Alerts/GetAssetName',
        cache: false,
        data: { AssetID: id },
        success: function (oData) {
          resolve(oData.data);
        }
      });
    });
  }

  getSiteIntIdByGuid(uuid: string): Promise<number> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Site/RmxId',
        cache: false,
        data: { uuid: uuid },
        dataType: 'json',
        success: function (oData) {
          resolve(oData);
        }
      });
    });
  }

  getInstallationName(companyName: string, siteName: string): Promise<string> {
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Home/GetInstallationName',
        cache: false,
        data: {
          CompanyName: companyName,
          SiteName: siteName
        },
        success: function (oData) {
          resolve(oData.data);
        }
      });
    });
  }

  getAlertDetailsAsync(id: string, type: string): Promise<any> {
    return new Promise((resolve) => {
      $.ajax({
        url: type === 'Site' ? '/Alerts/GetSiteAlertsMouseHover' : '/Alerts/GetCompanyAlertsMouseHover',
        method: 'get',
        data: type === 'Site' ? { SiteID: id } : { CompanyID: id },
        success: function (oData: { havedata: boolean; data: string }) {
          if (oData.havedata) {
            resolve(oData);
          }
        },
        error: function (result) {}
      });
    });
  }

  getCompanyOrSiteNameAsync(id: string, type: string): Promise<string> {
    // console.log(`id: ${id}, type: ${type}`)
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: type === 'Site' ? '/Alerts/GetSiteName' : '/Alerts/GetCompanyName',
        cache: false,
        data: type === 'Site' ? { SiteID: id } : { CompanyID: id },
        success: function (oData: { data: string }) {
          resolve(oData.data);
        }
      });
    });
  }

  getAssetDetails(id: number): Promise<AssetDetails> {
    // console.log(`id: ${id}, type: ${type}`)
    return new Promise((resolve) => {
      $.ajax({
        type: 'GET',
        url: '/Alerts/GetAssetDetails',
        cache: false,
        data: { assetId: id },
        success: function (oData: AssetDetails) {
          resolve(oData);
        }
      });
    });
  }

  async getIdByGuidAndType(guid: string, type: 'Company' | 'Site' | 'Asset') {
    return (await this.portalAxios.post('/Tiles/GetTargetId', { subjectId: guid, subjectType: type })).data;
  }
}

export function getLegacyPortalApi() {
  return new LegacyPortalApi();
}
