import { FieldRow } from '../RmxServiceActivityDetails/field-table';
import { Spinner } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { useRmxServiceApi } from '../api';
import type { TimeStatField } from '../Service.types';
import { RmxServiceDetails } from '../RmxServiceActivityDetails/RmxServiceDetails';
import { DateInput } from 'src/portal/utils/DateInput';
import { useState } from 'react';
import { humanizeDuration } from '../../utils/humaizeDuration';
import { formatCurrency } from '../../utils';
import { startOfWeek } from 'date-fns/startOfWeek';
import { endOfWeek } from 'date-fns/endOfWeek';
import { subHours } from 'date-fns';

export function TimeStats() {
  // Make Time Stats "From" time only editable to each Monday, and default the "To" to the following Sunday
  // Make sure that the values displayed follow this format
  // Change Paid Hours to "Total Hours" and move to the top
  const api = useRmxServiceApi();

  const [fromDate, setFromDate] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [toDate, setToDate] = useState<Date>(endOfWeek(new Date(), { weekStartsOn: 1 }));

  const { data: timeStats, isFetching } = useQuery({
    queryKey: ['timeStats', fromDate, toDate],
    queryFn: async () => {
      return api.getTimeStats({
        startDate: fromDate == null ? undefined : fromDate,
        endDate: subHours(toDate, 5)
      });
    },
    refetchOnWindowFocus: false
  });
  return (
    <>
      {isFetching ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner />
        </div>
      ) : (
        <>
          <div className='mb-3' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              From:
              <DateInput
                value={fromDate}
                filterDate={(date) => date.getDay() === 1}
                onChange={(date) => {
                  setFromDate(date!);
                  setToDate(endOfWeek(date!, { weekStartsOn: 1 }));
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              To:
              <DateInput disabled={true} value={toDate} onChange={() => {}} />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <RmxServiceDetails>
              <FieldRow<TimeStatField> value={convertHoursToTimeString(timeStats?.paidHours ?? 0)} header={'Total Hours'} name={'paidHours'} />
              <FieldRow<TimeStatField> value={convertHoursToTimeString(timeStats?.productivity ?? 0)} header={'Productive Hours'} name={'productivity'} />
              <FieldRow<TimeStatField> value={convertHoursToTimeString(timeStats?.travelHours ?? 0)} header={'Travel Hours'} name={'travelHours'} />
              <FieldRow<TimeStatField> value={timeStats?.travelRatio + '%'} header={'Travel Ratio'} name={'travelRatio'} />
              <FieldRow<TimeStatField>
                value={isNaN(timeStats?.revPerHour ?? 0) ? 0 : formatCurrency(timeStats?.revPerHour ?? 0)}
                header={'Revenue Per Hour'}
                name={'revPerHour'}
              />
              <FieldRow<TimeStatField>
                value={isNaN(timeStats?.totalRate ?? 0) ? 0 : formatCurrency(timeStats?.totalRate ?? 0)}
                header={'Total Rate'}
                name={'totalRate'}
              />
            </RmxServiceDetails>
          </div>
        </>
      )}
    </>
  );
}

function convertHoursToTimeString(hours: number): string {
  return humanizeDuration({ duration: hours * 60 * 60 * 1000, tempOptions: { units: ['d', 'h', 'm'] } });
}
