import React from 'react';
import {Dropzone, type ExtFile} from '@files-ui/react';
import {getAuthToken} from 'src/api';
import {type FileParentResource} from 'src/common/util/files';
import {getUploadUrl, removeFile, type StoredFile} from 'src/api/files';
import {FileMosaicWithDownload} from 'src/components/util/files/FileMosaicWithDownload';
import {arrayToMap} from '../../../common/util';

export function makeExtFileFromStoredFile(storedFile: StoredFile): ExtFile {
  return {
    id: storedFile.uuid,
    name: storedFile.displayName,
    size: storedFile.sizeBytes,
    type: storedFile.contentType,
    imageUrl: storedFile.signedThumbnailUrl ?? undefined,
    extraData: storedFile,
    downloadUrl: storedFile.fileName
  };
}


type Props = {
  singleFile?: boolean;
  initialFiles: StoredFile[];
  onUploadFinish?: (files: StoredFile[]) => Promise<any> | void;
  onDelete?: () => Promise<any> | void;
  onUpdateFiles?: (files: StoredFile[]) => Promise<any> | void;
} & FileParentResource;

export function FileDropZone({singleFile, parentResourceType, parentResourceId, initialFiles, onUploadFinish, onDelete: propOnDelete, onUpdateFiles}: Props) {
  const [files, setFiles] = React.useState<ExtFile[]>(() => initialFiles.map(makeExtFileFromStoredFile));
  const updateFiles = async (incomingFiles: ExtFile[]) => {
    if (singleFile) {
      setFiles(prevFiles => {
        // delete old files if they exist since there should only be one file in single mode
         for (const file of prevFiles) {
           // only delete the file if it is not the incoming file (this method gets called multiple times for the same file)
           if(file.id !== incomingFiles[0]?.id)
              void removeFile(String(file.id));
        }
        if (propOnDelete && prevFiles.length > 0)
          propOnDelete();
        // update our state with the first uploaded file
        return incomingFiles.slice(0, 1);
      });
    } else {
      // merge updates from the uploader into our files state.
      setFiles(prevFiles => {
        const prevFilesById = arrayToMap(prevFiles, f => f.id, f => f);
        const mergedIncomingFiles = incomingFiles.map((incomingFile) => {
          const file = prevFilesById.get(incomingFile.id);
          return file ? {...file, ...incomingFile} : incomingFile;
        });
        incomingFiles.forEach(f => prevFilesById.delete(f.id));
        return prevFiles.filter(pf => prevFilesById.has(pf.id)).concat(mergedIncomingFiles);
      });
    }
  };

  const onDelete = async (fileId: number | string | undefined) => {
    // eslint-disable-next-line no-restricted-globals
    if(!confirm('Are you sure you want to delete this file?')) {
      return;
    }
    if (fileId === undefined) {
      return;
    }
    const file = files.find(f => f.id === fileId);
    if (file?.id) {
      await removeFile(String(file!.id));
      if (propOnDelete) {
        await propOnDelete();
      }
    }
    setFiles(previousFiles => previousFiles.filter(f => f.id !== fileId));
  };

  return (
    <>

      <Dropzone
        uploadConfig={{
          headers: {
            Authorization: `Bearer ${getAuthToken()}`
          },
          method: 'POST',
          url: getUploadUrl(parentResourceId, parentResourceType),
          autoUpload: true
        }}
        headerConfig={{
          deleteFiles: false
        }}
        onUploadFinish={async (uploadedFiles) => {
          if (onUploadFinish) {
            await onUploadFinish(uploadedFiles.map(f => f.serverResponse?.payload as StoredFile));
          }
          console.log('uploadedFiles', uploadedFiles, files);
          setFiles((prevFiles) => {
            return prevFiles.map((file) => {
              const uploadedFile = uploadedFiles.find((f) => f.id === file.id);
              if (uploadedFile) {
                return {
                  ...file,
                  ...(uploadedFile.serverResponse?.payload ? makeExtFileFromStoredFile(uploadedFile.serverResponse?.payload as StoredFile) : {})
                };
              }
              return file;
            });
          });
        }}
        behaviour={singleFile ? 'replace' : 'add'}
        label={singleFile ? 'Drop your file here' : 'Drop your files here'}
        style={singleFile ? {maxWidth: 240} : {}}
        onChange={updateFiles}>
        {files.map((file) => (
          <FileMosaicWithDownload key={file.id} {...file} preview={true} onDelete={onDelete} />
        ))}
      </Dropzone>
    </>
  );
}


