import type { DashboardAlertDto } from '../../../../../../api/userDashboardApi';
import type { AlertColor } from '../../../../../../portal/status-indicator/StatusIndicator/StatusIndicator';
import { Orb } from '../Orb/Orb';
import { ReactTable, type SimpleColumnDef } from '../../../../../../portal/utils/ReactTable/ReactTable';
import React, { useMemo } from 'react';


type AlertTableType = 'Installation' | 'Asset';

const optionalArrayItem = <T extends unknown>(valueGetter: () => T, condition: boolean) => condition ? [valueGetter()] : [];

function useAlertsTableDefinition(type: AlertTableType) {
  return  useMemo(() => [
    {header: '', accessorKey: 'color', valueFormatter: (v: AlertColor) => <Orb color={v} />},
    ...optionalArrayItem(() => ({header: 'Asset', accessorKey: 'asset', style: {whiteSpace: 'nowrap'}}), type === 'Asset'),
    {header: 'Alert Definition', accessorKey: 'definition'},
    {header: 'Alert Description', accessorKey: 'description'},
    {header: 'Alert Response', accessorKey: 'response'},
    {header: 'Start Time', accessorKey: 'startTime', valueFormatter: (v: string) => new Date(v).toLocaleString()},
    ...optionalArrayItem(() => ({header: 'System Process', accessorKey: 'systemProcess'}), type === 'Installation'),
  ] as const satisfies SimpleColumnDef<DashboardAlertDto>[], [type]);
}
type Props = {
 alerts: DashboardAlertDto[];
 type: AlertTableType;
};

export function AlertTable({alerts, type}: Props) {
  const definition = useAlertsTableDefinition(type);
 return <>{alerts.length > 0 && <>
     <h4 style={{color: 'orange'}}>Active Alerts!</h4>
     <ReactTable className={'mb-4'} tableClassName={'time-table'} columns={definition} includeHeaders={true} striped={true} bordered={true} data={alerts} />
   </>}</>;
}
