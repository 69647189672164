import ReactDOM from 'react-dom/client';
import { type AlertColor, type StatusIndicatorSymbol, type StatusIndicatorProps, StatusIndicator } from './StatusIndicator/StatusIndicator';
import React, { useState } from 'react';
import { type ActionHandler } from '../menu-modal/ActionModal/ActionModal';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    BootstrapStatusIndicator: (
      targetId: string,
      color: AlertColor,
      symbol: StatusIndicatorSymbol,
      label: string,
      requestIndicatorNumber: number | null,
      handleOnClick: () => {}
    ) => void;
    updateStatusIndicator: StatusIndicatorProps;
    roots: { [key: string]: ReactDOM.Root };
  }
}

if (!window.roots) {
  // initializing the roots object if it doesn't exist
  window.roots = {};
}

export function BootstrapStatusIndicator() {
  const [statusColor, setColor] = useState<AlertColor | null>(null);
  const [statusSymbol, setSymbol] = useState<StatusIndicatorSymbol | null>(null);
  const [statusLabel, setLabel] = useState<string | null>(null);
  const [statusRequestIndicatorNumber, setRequestIndicatorNumber] = useState<number | null>(null);
  const [statusHandleOnClick, setHandleOnClick] = useState<ActionHandler<any>>();

  window.updateStatusIndicator = (color, symbol, text, requestIndicatorNumber, handleOnClick) => {
    setColor(color ?? null);
    setSymbol(symbol ?? null);
    setLabel(text ?? null);
    setRequestIndicatorNumber(requestIndicatorNumber ?? null);
    setHandleOnClick(() => handleOnClick);
    // console.log('passed in', handleOnClick);
    // console.log('statusHandleOnClick', statusHandleOnClick)
  };

  if (!statusColor || !statusSymbol || !statusLabel || !statusHandleOnClick) {
    return null;
  }

  return (
    <StatusIndicator
      color={statusColor}
      symbol={statusSymbol}
      label={statusLabel}
      requestIndicatorNumber={statusRequestIndicatorNumber}
      onClick={statusHandleOnClick}
    />
  );
}

window.BootstrapStatusIndicator = (targetId: string) => {
  let element = document.getElementById(targetId);
  if (!element) {
    element = document.createElement('div');
    element.id = targetId;
  }

  ReactDOM.createRoot(element).render(<BootstrapStatusIndicator />);
};
