import type { ComponentLifeResponse } from '../../../../../../portal/component-life/component-life-types';
import ReactDOM from 'react-dom/client';
import { openComponentLifeModal } from '../../../../../../portal/component-life/ComponentLifeComponent/ComponentLifeComponent';
import React from 'react';
import { ComponentLifeWrapper } from '../../../../../../portal/component-life';
import { AssetDetailsWrapper } from './AssetDetailsWrapper';
import { createStandardQueryClient } from '../../../../../../portal/utils/react-query/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ApplicationTypeProvider } from '../../../../../../ApplicationTypeContext';
import { hiddenFilter } from '../../../../../../api/userDashboardApi';

declare global {
  interface Window {
    initAssetDataPoints: (target: string, dataRequester: () => Promise<ComponentLifeResponse>, fromDashboard: boolean, assetId: number) => void;
  }
}
const queryClient = createStandardQueryClient();
export function initAssetDataPoints(target: string, dataRequester: () => Promise<ComponentLifeResponse>, fromDashboard: boolean, assetId: number) {
  hiddenFilter.ShowHidden = false;
  const root = ReactDOM.createRoot(document.getElementById(target) as HTMLElement);
  document.querySelector('.component-life-container')?.addEventListener('click', async () => openComponentLifeModal());

  root.render(
    <React.StrictMode>
      <ApplicationTypeProvider value="Portal">
      <QueryClientProvider client={queryClient}>
      <AssetDetailsWrapper showDiagnostics={false} />
      <ComponentLifeWrapper fromDashboard={fromDashboard} assetId={assetId} dataRequester={dataRequester} />
      </QueryClientProvider>
      </ApplicationTypeProvider>
    </React.StrictMode>
  );
}


window.initAssetDataPoints = initAssetDataPoints;
