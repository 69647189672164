import s from '../../../../../../../portal/rmx-service/RmxServiceForms/RmxServiceForms.module.scss';
import type { ModalState } from '../../../../../../../portal/hooks/useModal';
import { GenericModal } from '../../../../../../../portal/utils/GenericModal/GenericModal';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { updateRmxHours } from '../../../../../../../api/userDashboardApi';
import { handleAxiosError } from '../../../../../../../common/util/http';
import { Alert } from 'react-bootstrap';
import { LoadingButton } from '../../../../../../../portal/utils/widgets/LoadingButton/LoadingButton';
import { StandardInput } from '../../../../../../../components/util/form-components/StandardInput';

type Props = {
  state: ModalState<any>;
  assetId: number;
  rmxHours: number | null;
  hourMeterId: number|undefined;
  refetch: () => Promise<unknown>;
};

export function UpdateRmxHoursModal({ state, assetId, refetch, rmxHours: rmxHoursProp, hourMeterId }: Props) {

  const [rmxHours, setRmxHours] = useState(rmxHoursProp);

  const [submitting, setSubmitting] = useState(false);
  const { mutateAsync, error } = useMutation({
    mutationFn: updateRmxHours
  });
  const submit = async () => {
    try {
      setSubmitting(true);
      await mutateAsync({ assetId: assetId, hours: rmxHours, hourMeterId: hourMeterId! });
      await refetch();
      state.close();
    } finally {
      setSubmitting(false);
    }
  };
  return <GenericModal showClose={true} label="Coverage Type" className={s['modal']} contentClassName={s['modal-content']} state={state}>
    <>
      <StandardInput
        type={'number'}
        value={rmxHours ?? ''}
        onChange={v => setRmxHours(parseInt(v))}
      />
      <div className="d-flex mt-2">
        <LoadingButton
          className="btn-primary btn btn-small ml-auto ms-auto text-white"
          loading={submitting}
          onClick={submit}
          label="Submit"
        />
        <button className="btn-warning btn btn-small ml-2 ms-2 text-white" onClick={() => {
          state.close();
          refetch();
        }} disabled={submitting}>
          Cancel
        </button>
      </div>
      {error && <Alert variant={'danger'}>{handleAxiosError(error)}</Alert>}
    </>
  </GenericModal>;
}
