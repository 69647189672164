import styles from './formik-inputs/Input/Input.module.scss';
import React, {type ChangeEvent, useMemo} from 'react';
import { FormControl} from 'src/components/util/Controls';
import type {FormControlElement} from '../Controls/Form/Control/FormControl';

interface Props {
  type?: 'number' | 'text' | 'password' | 'email' | 'textarea' | 'color';
  disabled?: boolean;
  rows?: number;
  defaultValue?: any;
  emptyAsNull?: boolean;
  value: number | string;
  isInvalid?: boolean;
  onChange?: (value: string) => void;
}

export function StandardInput(props: Props) {
  const {onChange: onChangeProp} = props;
  const onChange = useMemo(() => (e: ChangeEvent<FormControlElement>) => onChangeProp!((e.currentTarget as unknown as HTMLInputElement).value), [onChangeProp]);
  return (
    <FormControl
      as={props.type === 'textarea' ? 'textarea' : 'input'}
      isInvalid={props.isInvalid}
      className={styles['form-inputs']}
      value={String(props.value)}
      onChange={onChangeProp ? onChange  : undefined}
      disabled={props.disabled}
      type={props.type || 'text'}
    />
  );
}
