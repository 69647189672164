import styles from './Section.module.scss';
import {AppTheme} from 'src/appTheme';
import {Container, Row} from 'src/components/util/Controls';
import React, {type PropsWithChildren} from 'react';
import {combine} from 'src/util';

export function Section({children, title, withBorder}: PropsWithChildren<{title: string; withBorder?: boolean}>) {
  return (
    <Container fluid={true} className={styles['section-wrapper']}>
      <h5 className={combine(styles['section-title'], withBorder ? styles['bordered'] : '')} style={AppTheme.styles.largerOffsetBackground}>{title}</h5>
      <Row className={styles['section-container']}>
        {children}
      </Row>
    </Container>
  );
}
