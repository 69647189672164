import React, {useMemo} from 'react';
import {type SimpleUserViewAsset} from 'src/common/redux/entities/installationEntities/assetRevision';
import { useApplyAssetPath } from 'src/pages/Dashboard/DashboardRoutePaths';
import {DashboardGrid} from 'src/components/util/Grid/DashboardGrid';
// import {addBypass} from 'src/api/userDashboardApi';
import {type ReloadableProps} from 'src/util/ReloadableProps';
import {Panel} from 'src/components/util/Panel/Panel';
import {combineAsArray} from 'src/util';
// import {useNavigate} from 'react-router-dom';
import { type MenuConfig, useShowMenu } from 'src/portal/menu-modal/MenuModal/MenuModal';
import type { ActionConfig } from 'src/portal/menu-modal/ActionModal/ActionModal';
import { useModal } from '../../../../../../portal/hooks/useModal';
import { NotesEditorModal, type NotesEditorModalParam } from '../../../../../../components/dashboard/NotesEditorModal';
import { useApplicationType } from '../../../../../../ApplicationTypeContext';


interface InstallationAssetSectionProps extends ReloadableProps{
  sectionLabel: string;
  assets: SimpleUserViewAsset[];
}
export function InstallationAssetSection({assets, sectionLabel, reload}: InstallationAssetSectionProps) {
  const applyAssetPath = useApplyAssetPath();
  return (
    <AssetSection
      reload={reload}
      assets={assets}
      sectionLabel={sectionLabel}
      getAssetLink={(a) => applyAssetPath(a)}/>
  );
}

interface AssetSectionProps extends ReloadableProps{
  sectionLabel: string;
  assets: SimpleUserViewAsset[];
  getAssetLink: (a: SimpleUserViewAsset) => string;
  withBorder?: boolean;
}
export function AssetSection({assets, getAssetLink, sectionLabel, reload}: AssetSectionProps) {

  const applicationType = useApplicationType();
  const {showMenu} = useShowMenu<SimpleUserViewAsset>();
  // const navigate = useNavigate();
  // this is the menuConfig that is passed into the showMenu function
  // because its generic we're able to define the handlers for our buttons which are specific to the AlertStatus type
  const notesEditorModal = useModal<NotesEditorModalParam>({onClose: () => reload()});

  const menuConfig: MenuConfig<SimpleUserViewAsset> = useMemo(() => ({
    open: r =>  {}, //navigate(getLinkByOrgLevel(r.uuid, r.name, 'Asset')),
    alerts: r => console.log('Displaying alerts modal'),
    graphing: r => console.log('Displaying graphing modal'),
    edit: (r) => notesEditorModal.open({type: r.orgLevel, id: r.id, notes: r.notes}),
    getLabel: r => r.name
  }), [notesEditorModal]);


  const actionConfig = useMemo(() => ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    view_requests: r => console.log('request', r),
    change: r => console.log('change', r),
    snooze: r => console.log('snooze', r),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    bypass_add: r => console.log('bypass', r),
    edit: r => console.log('edit', r)
  } as ActionConfig<SimpleUserViewAsset>), []);

  return (
    <Panel title={sectionLabel} darker={true}>
      <NotesEditorModal state={notesEditorModal} />
      <DashboardGrid leftAlign={true} noItemsText='No companies found.' items={assets ? assets.map(a => {
        return ({
          title: a.name,
          link: getAssetLink(a),
          backgroundColor: a.color,
          symbol: a.symbol,
          opStatusColor: a.opStatusColor,
          indicator: a.indicator,
          isAssetCard: true,
          descriptions: combineAsArray(a.manufacturerName, a.modelNumber, a.serialNumber),
          notes: a.notes,
          onRightClick: async () => {
            if(applicationType === 'Portal') {
              window.ShowActionModal(a.id, a.notes ?? undefined);
            } else {
              // await addBypass(a.id, 'Asset');
              // console.log(`Bypassing ${a.id}, ${a.orgLevel}`);
              showMenu(a, menuConfig, actionConfig);
              reload();
            }

          }});
      }) : []} />
    </Panel>
  );
}
