import React, {type ReactNode} from 'react';
import ReactModal, {type Styles as ModalStyles} from 'react-modal';
import s from './Modal.module.scss';
import type { ModalState } from '../../../portal/hooks/useModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';


const customStyles: ModalStyles = {
  content: {
  },
};

export type ModalProps = {
  showClose?: boolean;
  children: ReactNode;
  state: ModalState<any, any>;
  title?: string;
  noPadding?: boolean;
  afterLabel?: React.ReactNode;
  onClose?: () => unknown;
  contentClassName?: string;
  modalClassName?: string;
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

export function Modal({showClose, children, state, title, noPadding, onClose: onCloseProp, afterLabel, contentClassName, modalClassName}: ModalProps) {

  function closeModal() {
    state.close();
  }

  return (
    <ReactModal
      ariaHideApp={false}
      className={clsx(s['modal'], modalClassName)}
      isOpen={state.isOpen}
      onRequestClose={() => {}}
      style={customStyles}
      overlayClassName={s['overlay']}
    >
      <div
        className={s['modal-header']}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', color: '#FFFFFF', borderBottom: '1px solid #222', backgroundColor: '#444' }}
      >
        <div className={s['header-label']}>{title}</div>
        {afterLabel}
        {showClose && (
          <div className={s['close-wrapper']} onClick={onCloseProp ?? closeModal}>
            <FontAwesomeIcon className={s['close']} icon='times' />
          </div>
        )}
      </div>
      <div className={clsx(s['content'], contentClassName)} style={noPadding ? {padding: 0} : {}}>
        {children}
      </div>
    </ReactModal>
  );
}
