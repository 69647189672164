import React, {type CSSProperties, type FunctionComponent, type PropsWithChildren, type ReactNode} from 'react';
import {Col, Row, FormGroup, FormLabel} from 'src/components/util/Controls';

export type InputRowProps = PropsWithChildren<{
  label: string;
  columnSize?: number;
  labelSize?: number;
  hidden?: boolean;
  style?: CSSProperties;
  hintText?: string | ReactNode;
}>;

export const InputRow: FunctionComponent<InputRowProps> = ({columnSize, labelSize, hidden, label, children, style, hintText}) => {
  const labelSizeCalc = labelSize || 2;
  const largerLabelSize = Math.min(12, labelSizeCalc + 2);
  const valueColumnSize = columnSize || 4;
  const largerColumnSize =  Math.min(12, valueColumnSize + 2);
  return (
    <FormGroup as={Row} hidden={hidden} style={style}>
      <FormLabel column={true} xs={12}  sm={largerLabelSize} md={labelSizeCalc}>{label}</FormLabel>
      <Col xs={12} sm={largerColumnSize} md={valueColumnSize}>
        {children}
      </Col>
      {!!hintText &&
        <Col md={6} sm={8} xs={12}>
          <span>{hintText}</span>
        </Col>
      }
    </FormGroup>
  );
};
