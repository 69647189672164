import {useCallback} from 'react';

export interface HasPrefix {
  prefix: string;
}

export function isPrefixProps(val: { }|null|undefined): val is HasPrefix {
  if (typeof val !== 'object' || !val) {
    return false;
  }
  return 'prefix' in val && val.prefix !== undefined;
}

export const makeGetFieldName = <T>(props?: HasPrefix | string) => (property: keyof T & string): keyof T =>
  getFieldName(property, props) as keyof T;

export const useGetFieldName = <T extends object>(prefix?: string) =>
  useCallback((property: keyof T): Extract<keyof T, string> => `${prefix === undefined ? '' : prefix + '.'}${String(property)}` as Extract<keyof T, string>, [prefix]);
const getFieldName = (property: string, props?: HasPrefix | string): string =>
  typeof (props) === 'string' ? `${props}.${property}` : isPrefixProps(props) ? `${props.prefix}.${property}` : property;

export const makeGetArrayPropertyFieldName = <T>(parentFieldName: string) =>
  (index: number, property: keyof T & string) => `${parentFieldName}.${index}.${String(property)}`;

export const makeGetFieldIndex = (prefix: string) => (index: number): string => `${prefix}.${index}`;


export type ValueWithFieldName<T> = { value: T; fieldName: string};
