// eslint-disable-next-line no-restricted-imports
import {
  array as yupArray,
  type ArraySchema as YupArraySchema,
  boolean as yupBoolean,
  type BooleanSchema,
  number as yupNumber,
  type NumberSchema as YupNumberSchema,
  object as yupObject,
  type ObjectSchema as YupObjectSchema,
  type ObjectSchemaDefinition as YupObjectSchemaDefinition,
  type Schema,
  string as yupString,
  type StringSchema as YupStringSchema,
  type ValidationError as YupValidationError,
  type WhenOptionsBuilderObjectIs
} from 'yup';
import {getEnumValues, ipv4AddressRegex, macAddressNoSymbolsRegex} from './index';
import {toMutable} from 'src/util';
import {isNullOrWhitespace} from './string';

export const requiredFk = () => requiredNumber().min(1, 'Required');
export const nullableFk = () => nullableNumber().min(1, 'Must be a foreign key');
export const nullableFkRequiredWhen = <T>(key: string, val: T | null) => yupNumber().nullable().when(key, {
    is: val,
    then: (s: NumberSchema) => s.required('Required').min(1, 'Required')
  });

// tslint:disable-next-line:variable-name
export const baseNumber = () => yupNumber();
export const numberDefaultTo = (def: number) => yupNumber().default(def);
export const requiredNumberDefaultTo = (def: number) => yupNumber().typeError('must be a number').required('Required').default(def);
export const nullableNumber = () => yupNumber().typeError('Must be a number').nullable();
export const requiredNullableNumber = () => yupNumber().typeError('Must be a number').nullable().required('Required');
export const requiredNumberOneOf = <T extends  number>(items: T[]) => yupNumber().oneOf(items).required('Required') as YupNumberSchema<T>;
export const notRequiredNumber = () => yupNumber().notRequired();
export const requiredNumber = () => yupNumber().required('Required').typeError('Must be a number');
export const requiredNonzeroNumber = () => yupNumber().required('Required').typeError('Must be a number').notOneOf([0], 'Required');
export const requiredNumberBetween = (min: number, max: number, msg?: string) => yupNumber().required('Required').typeError('Must be a number')
  .min(min, msg ?? `Must be between ${min} and ${max}`)
  .max(max, msg ?? `Must be between ${min} and ${max}`);

export const nullableIntegerBetween = (min: number, max: number, msg?: string) => yupNumber().nullable().integer('Must be an integer').typeError('Must be a number')
  .min(min, msg ?? `Must be between ${min} and ${max}`)
  .max(max, msg ?? `Must be between ${min} and ${max}`);
export const requiredNumberWithLabel = (label: string) => yupNumber().required('Required').typeError('Must be a number').label(label);
export const requiredInteger = () => requiredNumber().integer('Must be an integer');
export const requiredIntegerOneOf = (arr: number[]) => requiredInteger().oneOf(arr);
export const requiredPositiveInteger = () => requiredInteger().min(0, 'Must be a positive number');

export const nullableNumberWhen = <T>(key: string, func: (data: T, schema: YupNumberSchema) => YupNumberSchema) => baseNumber().nullable().when(key, func);
export const nullableNumberRequiredWhen = <T>(key: string, val: T) =>
  baseNumber().nullable().when(key, {
    is: val,
    then:  (s: NumberSchema) => s.required('Required')
  });
export const nullableNumberRequiredWhenNotEqual = <T>(key: string, val: T) =>
  baseNumber().nullable().when(key, {
    is: val,
    otherwise:  (s: NumberSchema) => s.required('Required')
  });
export const nullableNumberRequiredWhenFunc = (keys: string | string[], condition: WhenOptionsBuilderObjectIs, requiredText: string = 'Required') =>
  baseNumber().nullable().when(keys, {
    is: condition,
    then:  (s: NumberSchema) => s.required(requiredText)
  });

/**
 * Mark this number required when the other property does not have a value. Useful for implementing one of two properties are required.
 * @param keys
 * @param requiredText
 */
export const nullableNumberRequiredWhenFalsyDependentValue = (keys: string, requiredText: string = 'Required') =>
  baseNumber().nullable().when(keys, {
    is: (otherValue, thisValue) => !otherValue && !thisValue,
    then:  (s: NumberSchema) => s.required(requiredText)
  });
export const nullableNumberRequiredWhenIn = <T>(key: string, includes: T[]) =>
  baseNumber().nullable().when(key, (val: T, s: NumberSchema) => includes.includes(val) ? s.required('Required') : s);
export const nullableNumberRequiredFkWhenNotIn = <T>(key: string, includes: T[]) =>
  baseNumber().nullable().when(key, (val: T, s: NumberSchema) => !includes.includes(val) ? s.min(1, 'Required').required('Required') : s);
export const nullableNumberRequiredWhenNot = <T>(key: string, val: T) =>
  baseNumber().nullable().when(key, {
    is: val,
    otherwise:  (s: NumberSchema) => s.required('Required')
  });

export const nullableStringOneOfAndRequiredWhen = <T extends string, U>(key: string, items: T[], compareVal: U) =>
  yupString().nullable().when(
    key,
    (val: U, s: StringSchema<T>) => val === compareVal ? s.oneOf(items).required('Required') : s) as StringSchema<T>;

export const stringOneOfAndRequiredWhenIn = <T extends string, U>(key: string, items: U[]) =>
  yupString().when(
    key,
    (val: U, s: StringSchema<T>) => items.includes(val) ? s.required('Required') : s) as StringSchema<T>;

export const requiredLabel = (minLength: number = 3) => yupString().required('Required').min(minLength, 'Must at least be 3 characters');
// tslint:disable-next-line:variable-name
export const baseString = <T extends string>() => yupString() as YupStringSchema<T>;
export const requiredNullableString = () => yupString().nullable().required('Required');
export const stringMatches = <T extends string>(regex: RegExp, msg?: string) => yupString().matches(regex, msg ? {message: msg} : undefined) as YupStringSchema<T>;
export const nullableStringMatches = <T extends string>(regex: RegExp, msg?: string) =>
  yupString().nullable().matches(regex, msg ? {message: msg} : undefined) as YupStringSchema<T>;
export const requiredStringMatches = <T extends string>(regex: RegExp, msg?: string) =>
  yupString().matches(regex, msg ? {message: msg} : undefined).required('Required') as YupStringSchema<T>;
export const nullableString = <T extends string>() => yupString().typeError('Must be a string').nullable() as YupStringSchema<T>;
const lengthErrorMsg = (length: number) => `Must be ${length} characters`;
export const nullableIMEI = () => nullableString().min(15, lengthErrorMsg(15)).max(15,lengthErrorMsg(15)).matches(/\d+/, 'Can only contain numbers');
export const nullableICCID = () => nullableString().min(20, lengthErrorMsg(20)).max(20, lengthErrorMsg(20)).matches(/\d+/, 'Can only contain numbers');


export const requiredMacAddress = () => requiredStringMatches(macAddressNoSymbolsRegex, 'Must be a valid mac address');
export const nullableIpAddress = () => nullableStringMatches(ipv4AddressRegex, 'The value must be an ip address');
export const stringOneOf = <T extends string>(items: T[] ) => yupString().oneOf(items) as YupStringSchema<T>;
export const requiredStringOf = <T extends string>(items: T[] ) => yupString().oneOf(items).required('Required') as YupStringSchema<T>;
export const requiredString = <T extends string>() => yupString().required('Required').trim() as YupStringSchema<T>;
export const requiredTruthyString = <T extends string>() => yupString().required('Required').min(1, 'Must at least be one character long') as YupStringSchema<T>;
export const requiredEmailAddress = <T extends string>() => yupString().required('Required').test('email-address', 'Must be a valid email address', (value: string|null) => {
  if(!value || (!value.includes('@') && !value.includes('.')))
    return false;
  const emailParts = value.split('@');
  if(emailParts.length !== 2)
    return false;
  if(emailParts[0].length < 1 || !emailParts[1].includes('.'))
    return false;
  const domainParts = emailParts[1].split('.');
  for(const part of domainParts) {
    if(part.length < 1)
      return false;
  }
  return true;
}) as YupStringSchema<T>;
export const notRequiredString = <T extends string>() => yupString().notRequired() as YupStringSchema<T>;
export const requiredStringLargerThan = <T extends string>(min: number) =>
  yupString().required('Required').min(min, `must be greater than ${min} characters`) as YupStringSchema<T>;
export const nullableRequiredString = <T extends string>() => yupString().nullable().required('Required') as YupStringSchema<T>;

export const nullableStringRequiredWhen = <T>(key: string, val: T) =>
  yupString().nullable().when(key, {
    is: val,
    then:  (s: StringSchema<string>) => s.min(1, 'Required').required('Required')
  });
export const nullableStringRequiredWhenIsNotNullOrWhitespace = <T extends string = string>(key: string, requiredText: string) =>
  yupString().nullable().when(key, (v: string | null, s: StringSchema<T>) => isNullOrWhitespace(v) ? s : s.required(requiredText)) as StringSchema<T>;

export const nullableStringRequiredWhenNot = <T>(key: string, val: T) =>
  yupString().nullable().when(key, {
    is: (v) => v !== val,
    then:  (s: StringSchema<string>) => s.min(1, 'Required').required('Required')
  });
// tslint:disable-next-line:variable-name
export const baseBoolean = () => yupBoolean();
export const requiredBoolean = () => yupBoolean().required('Required');
export const nullableBoolean = () => yupBoolean().nullable();
export const nullableBooleanRequiredWhen = <T>(key: string, val: T) =>
  yupBoolean().nullable().when(key, {
    is: val,
    then:  (s: BooleanSchema) => s.required('Required')
  });

export const requiredStringEnum = <T extends string>(enumObject: object) => requiredString().oneOf(getEnumValues(enumObject)) as YupStringSchema<T>;

export const requiredStringConst = <T extends string>(possibleValues: readonly T[]) => requiredString().oneOf(toMutable(possibleValues)) as YupStringSchema<T>;

export function object<T extends object>(obj: YupObjectSchemaDefinition<T>) {
  return yupObject(obj);
}

export const nullableObjectRequiredWhen = <T extends object>(schema: ObjectSchema<T>, key: string, val: unknown) => schema.nullable()
    .when(key, {is: val, then: (s: ObjectSchema) => s.required('Required')});
export const objectShape = <T extends object>(obj: YupObjectSchemaDefinition<T>, noSortEdges?: Array<[string, string]> | undefined) =>
  yupObject().shape(obj, noSortEdges);

export const notRequiredNullableObject = <T extends object>() => yupObject<T>().nullable().notRequired();
export const nullableObject = <T extends object>() => yupObject<T>().nullable();

export const baseArray = <T>() => yupArray<T>();
// @ts-ignore
type ArraySupportedSchemas<U> = Schema<U> | U extends object | null | undefined ?  ObjectSchema<U> : never | U extends number ? NumberSchema<U> : never;

export const arrayOf = <T, U = T>(
  of: ArraySupportedSchemas<U>
) => yupArray<T>().of<U>(of);
export const arrayOfMin = <T, U = T>(
  of: ArraySupportedSchemas<U>, min: number, msg: string = 'Required'
) => yupArray<T>().of<U>(of).min(min, msg);
export const arrayOfAtLeastOne = <T, U = T>(
  of: ArraySupportedSchemas<U>, msg: string = 'Required'
) => yupArray<T>().of<U>(of).min(1, msg);

export const arrayOfWhen = <T extends object | null | undefined, U extends object | null | undefined = T>(
  of: ArraySupportedSchemas<U>,
  key: string,
  func: (data: T, schema: YupArraySchema<T>) => YupArraySchema<T>) =>
  arrayOf<T, U>(of).when(key, func);

export type ObjectSchema<T extends object | null | undefined = object> = YupObjectSchema<T>;
export type NumberSchema<T extends number | null | undefined = number> = YupNumberSchema<T>;
export type StringSchema<T extends string = string> = YupStringSchema<T>;
export type ObjectSchemaDefinition<T extends object | null | undefined> = YupObjectSchemaDefinition<T>;
export type ValidationError = YupValidationError;
