import { type ModalState, useModal } from '../hooks/useModal';
import { GenericModal } from '../utils/GenericModal/GenericModal';
import { Formik, type FormikProps, useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { DropDownRow, InputRow, TextInputRow } from '../../components/util/form-components';
import { makeGetFieldName } from '../../util';
import { nullableString, object, requiredString } from '../../common/util/yup';
import { EditableContext } from '../../components/util/form-components/EditorForm/EditableContext';
import { Button } from 'react-bootstrap';
import type { TextInputProps } from '../../components/util/form-components/TextInputRow';
import { getLegacyPortalApi, type LocationDataResponse, type SiteDropDownOptionListResponse } from '../legacy-portal-api/legacy-api';
import { useMutation, useQuery, useQueryClient, type UseQueryOptions, type UseQueryResult } from '@tanstack/react-query';
import { ReactQueryLayout } from '../../components/util/LoadableResourceLayout';
import { SearchableDropdown } from '../../components/util/form-components/SearchableDropdown/SearchableDropdown';
import { LoadingButton } from '../utils/widgets/LoadingButton/LoadingButton';
import { getPortalServiceApi } from '../rmx-service/api/portal-api';

type ModalParams = { companyUuid: string; editMode: boolean };
const serviceApi = getPortalServiceApi();
const api = getLegacyPortalApi();

export function SiteEditorModal() {
  const modal = useModal<ModalParams>();
  const locationQuery = useQuery({
    queryFn: () => api.getLocationData(),
    queryKey: ['LocationData']
  });
  window.openSiteEditor = (companyUuid: string, editMode: boolean) => {
    void modal.open({ companyUuid: companyUuid, editMode: editMode });
  };
  return modal.isOpen ? <SiteEditorModalInner modal={modal} locationQuery={locationQuery} /> : null;
}

export type SiteFormValues = {
  name: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  stateProvince: string;
  postalCode: string;
  crmCustomerId: string | null;
};
const name = makeGetFieldName<SiteFormValues>();

function makeSiteFormValues(): SiteFormValues {
  return {
    name: '',
    address1: '',
    address2: '',
    city: '',
    country: 'United States',
    stateProvince: '',
    postalCode: '',
    crmCustomerId: null
  };
}

const schema = object<SiteFormValues>({
  name: requiredString(),
  address1: requiredString(),
  address2: nullableString(),
  city: requiredString(),
  country: requiredString(),
  stateProvince: requiredString(),
  postalCode: requiredString(),
  crmCustomerId: nullableString()
});

const defaultQueryOptions = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false
} satisfies Partial<UseQueryOptions>;

export function SiteEditorModalInner({
  modal,
  locationQuery
}: {
  modal: ModalState<ModalParams, undefined>;
  locationQuery: UseQueryResult<LocationDataResponse, Error>;
}) {
  const siteListQueryKey = ['siteList', modal.param!.companyUuid];
  const queryClient = useQueryClient();
  const siteListQuery = useQuery({
    queryFn: () => api.getSiteList(modal.param!.companyUuid),
    queryKey: siteListQueryKey,
    enabled: !!modal.param!.companyUuid,
    ...defaultQueryOptions
  });

  const { mutateAsync: saveAsync, isPending: isSaving } = useMutation({
    mutationFn: async (values: SiteFormValues) => {
      try {
        if (modal.param!.editMode) {
          await api.upsertSite(modal.param!.companyUuid, siteUuidRef.current!, values);
          await queryClient.invalidateQueries({ queryKey: makeSiteInfoQueryKey(siteUuidRef.current!) });
        } else {
          await api.upsertSite(modal.param!.companyUuid, null, values);
          await queryClient.invalidateQueries({ queryKey: siteListQueryKey });
        }
        modal.close();
        window.GetCompaniesGridListAll();
      } catch (e) {
        let errorMessage = 'failed to add/save site';
        if (typeof e === 'string') {
          errorMessage = e;
        }
        alert(errorMessage);
      }
    }
  });

  const siteUuidRef = useRef<string | null>(null);

  return (
    <EditableContext.Provider value={true}>
      <GenericModal state={modal} label={modal.param?.editMode ? 'Edit Site' : 'Add Site'} onClose={() => modal.close()} showClose={true}>
        <ReactQueryLayout state={locationQuery}>
          {() => (
            <Formik initialValues={makeSiteFormValues()} enableReinitialize={true} onSubmit={saveAsync} validationSchema={schema}>
              {(formik) => (
                <SiteEditorForm
                  isSaving={isSaving}
                  onSave={(uuid) => {
                    siteUuidRef.current = uuid;
                    void formik.submitForm();
                  }}
                  formik={formik}
                  locationData={locationQuery.data!}
                  modal={modal}
                  siteListQuery={siteListQuery}
                />
              )}
            </Formik>
          )}
        </ReactQueryLayout>
      </GenericModal>
    </EditableContext.Provider>
  );
}

function makeSiteInfoQueryKey(siteUuid: string | null) {
  return ['siteInfo', siteUuid];
}

export function SiteEditorForm({
  formik,
  locationData,
  modal,
  siteListQuery,
  onSave,
  isSaving
}: {
  formik: FormikProps<SiteFormValues>;
  locationData: LocationDataResponse;
  modal: ModalState<ModalParams, undefined>;
  siteListQuery: UseQueryResult<SiteDropDownOptionListResponse, Error>;
  onSave: (siteUuid: string | null) => void;
  isSaving: boolean;
}) {
  const columnSizeAttributes = {
    columnSize: 8,
    labelSize: 4
  } satisfies Partial<TextInputProps>;
  const [{ value: selectedCountry }] = useField<string>(name('country'));
  const countryOptions = locationData.Countries.map((c) => ({ label: c, value: c })) ?? [];
  const stateOrProvinceOptions = locationData.StatesByCountry[selectedCountry]?.map((s) => ({ label: s, value: s })) ?? [];

  const [selectedSiteUuid, setSelectedSiteUuid] = React.useState<string | null>(null);
  const siteOptions = siteListQuery?.data?.map((s) => ({ label: s.siteName, value: s.siteUuid })) ?? [];
  const customerConfirmationQuery = useQuery({
    queryFn: async () => await serviceApi.getCustomerByCrmId(formik.values.crmCustomerId!),
    ...defaultQueryOptions,
    queryKey: ['customer-info', formik.values.crmCustomerId!],
    enabled: !!formik.values.crmCustomerId,
    retry: false
  });
  const customer = customerConfirmationQuery.data;
  const siteInfoQuery = useQuery({
      queryFn: async () => await api.getSiteInfo(selectedSiteUuid!),
      ...defaultQueryOptions,
      queryKey: makeSiteInfoQueryKey(selectedSiteUuid),
      enabled: !!selectedSiteUuid
    }),
    siteInfo = siteInfoQuery.data;
  const formikRef = useRef(formik);
  useEffect(() => {
    if (siteInfo) {
      const newValues: SiteFormValues = {
        name: siteInfo.name,
        address1: siteInfo.address1,
        address2: siteInfo.address2,
        city: siteInfo.city,
        country: siteInfo.country,
        stateProvince: siteInfo.state,
        postalCode: siteInfo.postalcode,
        crmCustomerId: siteInfo.crmCustomerId
      };
      void formikRef.current.setValues(newValues);
      setConfirmedCrmId(newValues.crmCustomerId);
    }
  }, [siteInfo]);
  const [confirmedCrmId, setConfirmedCrmId] = useState<string | null>(null);
  const canSave =
    (selectedSiteUuid || !modal.param!.editMode) && (formik.values.crmCustomerId === null || confirmedCrmId === String(formik.values.crmCustomerId));
  return (
    <>
      <div className='mb-4'>
        <span>Fill out the required information and click the 'Create Site' button.</span>
      </div>
      {modal.param?.editMode && (
        <ReactQueryLayout state={siteListQuery} shrink={true}>
          <InputRow label='Sites' {...columnSizeAttributes}>
            <SearchableDropdown dropdownData={siteOptions} onSelect={(v) => setSelectedSiteUuid(v)} />
          </InputRow>
        </ReactQueryLayout>
      )}
      {!(modal.param?.editMode && siteListQuery.isPending) && (
        <ReactQueryLayout state={siteInfoQuery} showIfNoData={!modal.param?.editMode} showLoaderOnRefetch={true}>
          {() => (
            <>
              <TextInputRow label='Site Name' name={name('name')} {...columnSizeAttributes} />
              <TextInputRow label='Address Line 1' name={name('address1')} {...columnSizeAttributes} />
              <TextInputRow label='Address Line 2' emptyAsNull={true} name={name('address2')} {...columnSizeAttributes} />
              <TextInputRow label='City' name={name('city')} {...columnSizeAttributes} />
              <DropDownRow
                label={'Country'}
                name={name('country')}
                options={countryOptions}
                {...columnSizeAttributes}
                onSelect={() => formik.setFieldValue(name('stateProvince'), '')}
              />
              <DropDownRow options={stateOrProvinceOptions} label='State/Province' name={name('stateProvince')} {...columnSizeAttributes} />
              <TextInputRow label='Postal Code' name={name('postalCode')} {...columnSizeAttributes} />
              <div className='mb-4'>
                <span>Please search for a matching customer in the crm if there is one and put its ID here</span>
              </div>
              <TextInputRow emptyAsNull={true} label='Crm Customer Id' name={name('crmCustomerId')} {...columnSizeAttributes} />

              {confirmedCrmId !== formik.values.crmCustomerId && formik.values.crmCustomerId !== null && (
                <>
                  <div className='mt-2 mb-2'>Please confirm the customer information below:</div>
                </>
              )}
              <ReactQueryLayout state={customerConfirmationQuery} shrink={true} showLoaderOnRefetch={true}>
                {customer && (
                  <div className='mt-2 mb-2'>
                    <table className={'table-sm'}>
                      <tbody>
                        <tr>
                          <td>Name:</td>
                          <td>{customer.name}</td>
                        </tr>
                        <tr>
                          <td>Address:</td>
                          <td>
                            {customer.address}, {customer.city}, {customer.state} {customer.zip}
                          </td>
                        </tr>
                        <tr>
                          <td>Notes:</td>
                          <td>{customer.notes}</td>
                        </tr>
                      </tbody>
                    </table>
                    {confirmedCrmId !== formik.values.crmCustomerId && (
                      <>
                        <Button className='mt-2' onClick={() => setConfirmedCrmId(formik.values.crmCustomerId)}>
                          Confirm
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </ReactQueryLayout>
              <div className='d-flex justify-content-end'>
                <LoadingButton loading={isSaving} onClick={() => (canSave ? onSave(selectedSiteUuid) : null)} label='Save' />
                <Button disabled={isSaving} className='ml-3 ms-3' variant='secondary' type='button' onClick={() => modal.close()}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </ReactQueryLayout>
      )}
    </>
  );
}
