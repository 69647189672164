import {Link} from 'src/router';
import {Card, CardBody} from 'src/components/util/Controls';
import React from 'react';
import styles from './LinkedCenteredCard.module.scss';
import {combineClasses} from 'src/util';

export function LinkedCard(props: {link: string; linkState?: object; children: any; className?: string}) {
  return (
    <Link className={combineClasses(styles['linked-card'])} to={props.link} state={props.linkState}>
      <Card className={combineClasses(styles['card'], props.className)}>
        <CardBody className={styles['card-body']}>
          {props.children}
        </CardBody>
      </Card>
    </Link>
  );
}
