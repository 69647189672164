import React from 'react';
import {InstallationAssetSection} from '../AssetSection/AssetSection';
import {type InstallationData} from 'src/api/userDashboardApi';
import {type ReloadableProps} from 'src/util/ReloadableProps';

interface InstallationTabProps extends ReloadableProps {
  installationData: InstallationData;
}

export function AssetsTab({installationData, reload}: InstallationTabProps) {
  return (
    <>
      {Object.keys(installationData.unmonitoredAssets).map((key: string) =>
        <InstallationAssetSection reload={reload} key={key} sectionLabel={`${key} - (Unmonitored)`} assets={installationData.unmonitoredAssets[key]}/>)}
      {Object.keys(installationData.assets).map((key: string) =>
        <InstallationAssetSection reload={reload} key={key} sectionLabel={key} assets={installationData.assets[key]}/>)}
    </>
  );
}
