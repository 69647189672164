import s from '../../../../../../../portal/rmx-service/RmxServiceForms/RmxServiceForms.module.scss';
import type { ModalState } from '../../../../../../../portal/hooks/useModal';
import { GenericModal } from '../../../../../../../portal/utils/GenericModal/GenericModal';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAssetCoverageTypes, upsertCoverageType } from '../../../../../../../api/userDashboardApi';
import { ReactQueryLayout } from '../../../../../../../components/util/LoadableResourceLayout';
import { convertToDropDownOptions } from '../../../../../../../common/util';
import { handleAxiosError } from '../../../../../../../common/util/http';
import { CreatableDropdown, type DropdownOption } from '../../../../../../../portal/utils/CreatableDropdown/CreatableDropdown';
import { Alert } from 'react-bootstrap';
import { LoadingButton } from '../../../../../../../portal/utils/widgets/LoadingButton/LoadingButton';
// import { Alert } from 'react-bootstrap';

type Props = {
  state: ModalState<any>;
  assetId: number;
  refetch: () => Promise<unknown>;
};

function useCoverageTypes(assetId: number) {
  return useQuery({
    queryKey: ['coverage-types', assetId],
    queryFn: async () => getAssetCoverageTypes(assetId),
    // required because a useState is used on the data from this request and if stale data is received, the updated data will not show in the UI. ei. never show stale data.
    gcTime: 0,
    placeholderData: undefined
  });
}

export function CoverageTypeModal({ state, assetId, refetch }: Props) {
  const query = useCoverageTypes(assetId);
  return <GenericModal showClose={true} label="Coverage Type" className={s['modal']} contentClassName={s['modal-content']} state={state}>
    <ReactQueryLayout state={query}>{() =>
      <InnerCoverageTypeModal query={query} state={state} refetch={refetch} assetId={assetId} />
    }
    </ReactQueryLayout>
  </GenericModal>;
}

type InnerCoverageTypeModalProps = {
  state: ModalState<any>;
  query: ReturnType<typeof useCoverageTypes>;
  refetch: () => Promise<unknown>;
  assetId: number;
};

function InnerCoverageTypeModal({query, state, refetch, assetId}: InnerCoverageTypeModalProps) {
  const [submitting, setSubmitting] = useState(false);
  const data = query.data!;
  const [selectedCoverageType, setSelectedCoverageType] = useState<DropdownOption<number|null>>(() => ({
    value: data.currentCoverageTypeId,
    label: data.coverageTypes.find(o => o.id === data.currentCoverageTypeId)?.name ?? 'Select'
  }));
  const options = useMemo(() => convertToDropDownOptions(data.coverageTypes) as DropdownOption<number|null>[] ?? [], [data]).concat(selectedCoverageType.value === null ? [selectedCoverageType]: []);
  const  {mutateAsync, error} = useMutation({
    mutationFn: upsertCoverageType
  });
  const submit = async () => {
    try {
      setSubmitting(true);
      await mutateAsync({ assetId: assetId, coverageTypeId: selectedCoverageType.value ?? 0, coverageTypeName: selectedCoverageType.label });
      await refetch();
      state.close();
    } finally {
      setSubmitting(false);
    }
  };
  return <>
    <CreatableDropdown
      options={options}
      value={selectedCoverageType}
      isClearable={true}
      onNewValue={(v) => setSelectedCoverageType({value: null, label: v})}
      onSelect={(v) => setSelectedCoverageType({value: v, label: options.find(o => o.value === v)!.label})}
    />
    {error && <Alert className={'mb-2 mt-2'} variant={'danger'}>{handleAxiosError(error)}</Alert>}
    <div className="d-flex mt-2">
      <LoadingButton
        className="btn-primary btn btn-small ml-auto ms-auto text-white"
        loading={submitting}
        onClick={submit}
        label="Submit"
      />
      <button className="btn-warning btn btn-small ml-2 ms-2 text-white" onClick={() => {state.close(); refetch();}} disabled={submitting}>
        Cancel
      </button>
    </div>
  </>;
}
