import Input, {type InputType} from 'src/components/util/form-components/formik-inputs/Input/Input';
import {InputRow} from 'src/components/util/form-components/InputRow';
import React, {useContext} from 'react';
import {EditableContext} from 'src/components/util/form-components/EditorForm/EditableContext';
import {type InputRowInputProps} from 'src/components/util/form-components/InputRowInputProps';

export type TextInputProps = InputRowInputProps & {type?: InputType};

export function TextInputRow({label, name, hintText, emptyAsNull, columnSize, type, labelSize}: TextInputProps) {
  const editable = useContext(EditableContext);
  return (
    <InputRow label={label} hintText={hintText} columnSize={columnSize} labelSize={labelSize}>
      <Input name={name} disabled={!editable} emptyAsNull={emptyAsNull} type={type}/>
    </InputRow>
  );
}
