import {LinkedCard} from './LinkedCenteredCard/LinkedCard';
import React from 'react';
import style from './GridItem.module.scss';
import {Icon} from './widgets/Icon/Icon';
import { Col } from './Controls';
import { type ColProps } from './Controls/Column/Col';

export type GirdItemProps = {
  title: string;
  link: string;
} & ColProps;

export function GridItem({title, link, xs, lg, md, sm, xl}: GirdItemProps) {
  return (
    <Col xl={xl ?? 2} lg={lg ?? 3} md={md ?? 4} sm={sm} xs={xs ?? 12} style={{marginBottom: '1rem', textAlign: 'center'}}>
      <LinkedCard link={link} className={style['card']}>
        <span className={style['title']}>{title}</span>
        <div className={style['tile-indicators']}>
            <Icon icon={'check'} className={style['icon']}  customSize={40} />
        </div>
      </LinkedCard>
    </Col>
  );
}
