import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import s from './GenericModal.module.scss';
import React, { type ReactNode } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type ModalState } from '../../hooks/useModal';
import type { ModalProps } from 'react-bootstrap/Modal';

export type GenericModalProps<TParam = unknown, TReturn = unknown> = {
  label?: string | ReactNode;
  afterLabel?: React.ReactNode;
  showClose?: boolean;
  onClose?: () => unknown;
  // shrinks to the contents
  shrinkMode?: boolean;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  bodyClassName?: string;
  dialogClassName?: string;
  onExit?: () => void;
  showHeader?: boolean;
  fullScreen?: ModalProps['fullscreen'];
} & OptionalModalState<TParam, TReturn>;

type OptionalModalState<TParam, TReturn> =
  | {
      open: boolean;
      setIsOpen?: (open: boolean) => void;
      state?: undefined;
    }
  | {
      open?: undefined;
      setIsOpen?: undefined;
      state?: ModalState<TParam, TReturn>;
    };

export function GenericModal<TParam = unknown, TReturn = unknown>({
  className,
  dialogClassName,
  contentClassName,
  bodyClassName,
  open,
  state,
  setIsOpen,
  label,
  children,
  onClose: onCloseProp,
  onExit,
  shrinkMode,
  showClose,
  fullScreen,
  showHeader,
  afterLabel
}: GenericModalProps<TParam, TReturn>) {
  if (state) {
    open = state.isOpen;
    setIsOpen = state.setIsOpen;
  }
  const close = () => {
    setIsOpen?.(false);
    onExit?.();
  };
  return (
    <Modal
      className={className}
      contentClassName={clsx(contentClassName && contentClassName, s['modal-content'], shrinkMode && s['shrink-modal_content'])}
      dialogClassName={clsx(dialogClassName, shrinkMode && s['shrink-modal'], s['modal-dialog'])}
      show={open ?? false}
      centered={true}
      fullscreen={fullScreen}
    >
      {(label || showHeader || afterLabel) && (
        <ModalHeader
          className={s['modal-header']}
          style={{ justifyContent: 'center', alignItems: 'center', height: 'auto', color: '#FFFFFF', borderBottom: '1px solid #222', backgroundColor: '#444' }}
        >
          <div className={s['header-label']}>{label}</div>
          {afterLabel}
          {showClose && (
            <div className={s['close-wrapper']} onClick={onCloseProp ?? close}>
              <FontAwesomeIcon className={s['close']} icon='times' />
            </div>
          )}
        </ModalHeader>
      )}
      <ModalBody className={clsx(s['modal-body'], bodyClassName)} style={{ color: '#FFFFFF', backgroundColor: '#262626' }}>
        {children}
      </ModalBody>
    </Modal>
  );
}
