import React, {type CSSProperties, memo, type MouseEventHandler, type Ref} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {type IconProp, type SizeProp} from '@fortawesome/fontawesome-svg-core';
import styles from './IconButton.module.scss';
// import ReactTooltip from 'react-tooltip';
// todo: temporarily remove react-tooltip until performance issues are fixed.
import {combineClasses} from '../../../../util';
import { useNavigationLink } from '../../../../router/UseNavigationLink';

interface IconButtonProps {
  icon: IconProp;
  hideIcon?: boolean;
  size?: SizeProp;
  customSize?: number;
  styles?: CSSProperties;
  linkStyle?: CSSProperties;
  iconToolTipText?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  children?: any;
  link?: string;
  disableHover?: boolean;
  className?: string;
  linkClassName?: string;
  color?: string;
  refProp?: Ref<any>;
  newTab?: boolean;
}

const IconButton = memo((props: IconButtonProps) => {
  const style: CSSProperties = { cursor: props.disabled ? 'cursor' : 'pointer', ...props.styles, ...(props.color ? {color: props.color} : props.disabled ? {color: 'gray'} : {}) };
  let button = null;
  const commonProps = {
    'data-tip': props.iconToolTipText,
    'title': props.iconToolTipText,
    'className': styles['icon-button'],
    'style': {display: 'flex', alignItems: 'center'},
    'hidden': props.hideIcon,
    'ref': props.refProp
  };
  const Link = useNavigationLink();
  if (props.link) {
    button = (
      <Link {...commonProps} to={props.link} style={props.linkStyle} className={combineClasses(styles['link'], props.linkClassName)} target={props.newTab ? '_blank' : undefined}>
        <IconButtonIcon {...props} />
      </Link>
    );
  } else {
    button =  (
      <span {...commonProps} onClick={props.disabled ? undefined : props.onClick} style={props.linkStyle}>
        <IconButtonIcon {...props} />
      </span>
    );
  }
  return (
    <span style={{display: 'inline-block', ...style}} className={props.className}>{button}</span>
  );
});
IconButton.displayName = 'IconButton';

function IconButtonIcon(props: IconButtonProps) {
  return (
    <React.Fragment>
      <FontAwesomeIcon
        icon={props.icon}
        size={props.size}
        className={styles['icon']}
        style={{cursor: 'pointer', fontSize: props.customSize, color: props.color}}
      />
      {props.children ? <span style={{marginLeft: '1rem'}}>{props.children}</span> : null}
    </React.Fragment>
  );
}

export default IconButton;
