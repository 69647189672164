import {type Action} from 'redux';
import type Redux from 'redux';
import {batchActions} from 'redux-batched-actions';
import {type IdDictionary} from '../util';
import {createAction, createReducer} from 'typesafe-actions';
import {type EnhancedReducer} from './typesafe-actions-types';
import {type ComponentType, useEffect, useRef} from 'react';
import {connect, type GetProps, type MapDispatchToPropsParam, type MapStateToPropsParam, type Matching, type ResolveThunks} from 'react-redux';
import isEqual from 'lodash/isEqual';

export const batchAppActions = <T extends Redux.AnyAction>(actions: T[], type?: string) => batchActions(actions, type);

/**
 * A null type placeholder to prevent having to specify generic arguments
 */
export const placeholder = <T>() => null as any as T;

export function createStandardActions<T, S extends string, SA extends string>(instance: T, setType: S, saveType: SA) {
  const set = createAction(setType)<IdDictionary<T>>();
  const save = createAction(saveType)<T>();
  return {
    set,
    save
  };
}


export type GetActions<T extends { [key: string]: (...args: any) => any }> = ReturnType<T[keyof T]>;


export function standardItemsReducer<T, A extends Action>(actions: { set: any; save: any}): EnhancedReducer<IdDictionary<T>, A> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  return (createReducer<IdDictionary<T>, A>({})
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    .handleAction(actions.set, (state, action) => action.payload) as any)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    .handleAction(actions.save, (state: any, action: any) => ({...state, [action.payload.id]: action.payload})) as EnhancedReducer<IdDictionary<T>, A>;
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useDeepCompareEffect<T>(func: () => void, deeplyNestedObject: T, ...others: any[]) {
  const prevDeeplyNestedObject = usePrevious(deeplyNestedObject);
  useEffect(() => {
    if (
      !isEqual(
        prevDeeplyNestedObject,
        deeplyNestedObject,
      )
    ) {
      func();
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,react-hooks/exhaustive-deps
  }, [deeplyNestedObject, prevDeeplyNestedObject, ...others]);
}

type MapperObject<TStateProps, TDispatchProps, TOwnProps, State> = {
  mapStateToProps: MapStateToPropsParam<TStateProps, TOwnProps, State>;
  mapDispatchToProps: MapDispatchToPropsParam<TDispatchProps, TOwnProps>;
};

export function connectRedux<
  C extends ComponentType<Matching<TStateProps & ResolveThunks<TDispatchProps>, GetProps<C>>>,
  TStateProps,
  TDispatchProps,
  TOwnProps,
  State
  >(
  component: C,
  {mapDispatchToProps, mapStateToProps}: MapperObject<TStateProps, TDispatchProps, TOwnProps, State>
) {
  const connector =
    connect<TStateProps, TDispatchProps, TOwnProps, State>(mapStateToProps, mapDispatchToProps);
  return connector(component);
}
