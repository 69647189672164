import React from 'react';
import { getPortalServiceApi } from '../../../../../../../portal/rmx-service/api/portal-api';
import { useMutation } from '@tanstack/react-query';
import type { UserAssetResponse } from '../../../../../../../api/userDashboardApi';
import { LoadingButton } from '../../../../../../../components/util/widgets/LoadingButton/LoadingButton';
import { Alert } from 'react-bootstrap';
import { handleAxiosError } from '../../../../../../../common/util/http';
import { downloadBlob } from '../../../../../../../util/blob-utils';

const api = getPortalServiceApi();

type Props = {
  asset: UserAssetResponse;
};
export function AssetReportButton({asset}: Props) {
  const assetId = asset.id;
  const {mutateAsync, isPending, error} = useMutation({
    mutationFn: async () =>  {
      const blob = await api.getLastLoadedReport({assetId: assetId, timeZone:  Intl.DateTimeFormat().resolvedOptions().timeZone});
      await downloadBlob(blob, `${asset.companyName} - ${asset.siteName} - ${asset.name} - Asset Report - ${Date.now()}.png`);
      },
  });

  const generateAssetReport = async () => {
    await mutateAsync();
  };
  return (
    <>
      {error && <Alert variant="danger">{handleAxiosError(error)}</Alert>}
      <LoadingButton loading={isPending} variant={'secondary'} size='lg'className="mb-3" onClick={e => {
        e.preventDefault();
        void generateAssetReport();
      }} label="Download Asset Report" />
    </>
  );
}


