import type { AlertColor } from '../../../../../../portal/status-indicator/StatusIndicator/StatusIndicator';
import s from './Orb.module.scss';
import clsx from 'clsx';
import React from 'react';

type OrbProps = {
  color: AlertColor;
};

export function Orb({ color }: OrbProps) {
  const colorStyle =
    color === 'Red' ? s['orb-red'] :
      color === 'Blue' ? s['orb-blue'] :
        color === 'Orange' ? s['orb-yellow'] :
          color === 'Gray' ? s['orb-gray'] :
            s['orb-gray'];
  return (<>
    <div className={clsx(s['orb'], colorStyle)} />
  </>);
}
