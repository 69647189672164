import React, { useCallback } from 'react';
import { SystemProcessComponent } from '../SystemProcessComponent/SystemProcessComponent';
import { seconds } from 'src/common/util';
import { getSystemProcessData } from 'src/api/userDashboardApi';
import { useQuery } from '@tanstack/react-query';
import { ReactQueryLayout } from '../../../../../../components/util/LoadableResourceLayout';

interface SystemProcessTabProps {
  systemProcessUuid: string;
  active: boolean;
  refetch: () => Promise<unknown>;
}

export function SystemProcessTab({ systemProcessUuid, active, refetch: refetchInstallation}: SystemProcessTabProps) {
  const queryResult = useQuery({
    queryKey: ['system-process-data', systemProcessUuid],
    queryFn: async () => await getSystemProcessData(systemProcessUuid),
    refetchInterval: active ? seconds(10) : false,
  }), { data: spData, refetch } = queryResult;
  const refetchAll = useCallback(async () => {
    await refetch();
    await refetchInstallation();
  }, [refetch, refetchInstallation]);
  return (
    <ReactQueryLayout state={queryResult}>
      {spData && active && <SystemProcessComponent reload={refetchAll} spData={spData!} />}
    </ReactQueryLayout>
  );
}
