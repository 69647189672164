import React, {useState} from 'react';
import styles from './AssetDetailContent.module.scss';
import {Table} from 'src/components/util/Controls';
import {combineClasses} from 'src/util';
import {type UserAssetResponse, type UserDataPointResponse} from 'src/api/userDashboardApi';
import clsx from 'clsx';
import { CoverageTypeModal } from './CoverageTypeModal/CoverageTypeModal';
import { useModal } from '../../../../../../portal/hooks/useModal';
import { UpdateRmxHoursModal } from './UpdateRmxHoursModal/UpdateRmxHoursModal';
import { numberOrNull } from '../../../../../../portal/utils';
import { AssetComponentLifeSection } from './AssetComponentLifeSection/AssetComponentLifeSection';

type Props  = {
  asset: UserAssetResponse | undefined;
  refetch: () => Promise<unknown>;
  showDiagnostics: boolean;
};

export function AssetDetailContent({asset, refetch, showDiagnostics}: Props) {
  const coverageTypeModal = useModal<any>();
  const updateRmxHoursModal = useModal<any>();
  if (!asset) {
    return <></>;
  }
  const {dataPoints, operationalStatuses} = asset;
  let productionStatuses = asset?.productionStatuses ?? [];
  const rmxRunningHours = productionStatuses.find(ps => ps.name === 'RMX Running Hours');
  if(rmxRunningHours) {
    productionStatuses = productionStatuses.filter(ps => ps.name !== 'RMX Running Hours');
  }
  return (
    <div className={styles['asset-detail-container']}>
      <DataColumn>
        <DataTable boldLabel={true} rows={operationalStatuses?.map(s => ({name: s, boldName: true, value: ''})) ?? []} title='Operational Status' />
      </DataColumn>
      <DataColumn>
          <DataTable rows={[
          ...convertDpsToRows(productionStatuses, showDiagnostics),
          ...(rmxRunningHours ? [{...convertDpToRow(rmxRunningHours, false), linkAction: () => updateRmxHoursModal.open(undefined)}] : []),
          ]} title='Production Status'/>
        <AssetComponentLifeSection components={asset?.components} enabled={asset?.componentLifeEnabled} />
      </DataColumn>
      <DataColumn>
        <DataTable rows={convertDpsToRows(dataPoints ?? [], showDiagnostics)} title='Data Points'/>
      </DataColumn>
      <DataColumn>
        <DataTable
          rows={[
            {name: 'Asset Group', value: asset?.assetGroup?.name ?? 'No Asset Group'},
            {name: 'Manufacturer', value: asset?.manufacturer?.name ?? 'No Manufacturer'},
            ...convertDpsToRows(asset.specs),
            {
              name: 'Coverage Type',
              value: asset?.coverageType ? `${asset.coverageType.name} (${asset.coverageType.code})` : "Unspecified",
              linkAction: () => { void coverageTypeModal.open(undefined);}
            },
          ]}
          title='Specifications'
        />
      </DataColumn>
      {coverageTypeModal.isOpen && <CoverageTypeModal state={coverageTypeModal} assetId={asset.id} refetch={refetch} />}
      {updateRmxHoursModal.isOpen && <UpdateRmxHoursModal
        state={updateRmxHoursModal}
        assetId={asset.id}
        refetch={refetch}
        rmxHours={numberOrNull(rmxRunningHours?.value)}
        hourMeterId={rmxRunningHours?.hourMeterId ?? undefined}
      />}
    </div>

  );
}

const DataColumn = ({children}: {children: any}) => (
  <div className={styles['data-column']}>
    {children}
  </div>
);


function AnimatableCell({text, className, linkAction}: {text: string; className?: string; linkAction: TableRow['linkAction']}) {
  const [lastText, setLastText] = useState<string | undefined>();
  if (lastText !== text && lastText !== undefined) {
    setTimeout(() => setLastText(text), 80);
  }
  if (lastText === undefined) {
    setLastText(text);
  }

  const cellClassName = clsx(styles['value-cell'], lastText !== text && styles['value-cell-flash'], !linkAction && styles['color-white']);
  return (
    <td className={className}>
      {linkAction
        ? <a href={'#'} onClick={(e) => {e.preventDefault(); linkAction(); }} className={cellClassName}>{text}</a>
        : <span className={cellClassName}>{text}</span>
      }
    </td>
  );
}

type TableRow = {name: string; value?: number | string; boldName?: boolean; linkAction?: () => unknown};
const convertDpsToRows = (dps: UserDataPointResponse[], showDiagnostics: boolean = false): TableRow[] =>
  dps.map(dp => convertDpToRow(dp, showDiagnostics));

const convertDpToRow = (dp: UserDataPointResponse, showDiagnostics: boolean = false): TableRow =>
 ({name: showDiagnostics ? getDpRowLabel(dp) : dp.name, value: getValue(dp)});

const DataTable = ({rows, title, boldLabel}: {rows: TableRow[]; title?: string; boldLabel?: boolean}) =>  (
  <div>
    {title && <h2 className={combineClasses(styles['title'], rows.length === 0 && styles['bordered'])}>{title}</h2>}
    <Table className={styles['data-table']}>
      <tbody>
      <DataRows boldLabel={boldLabel ?? false} rows={rows} />
      </tbody>
    </Table>
  </div>
);

const DataRow = ({name, value, boldName, linkAction}: TableRow) => (
  <tr className={styles['data-row']}>
    <td className={combineClasses('text-left', boldName && styles['bolded'])}>{name}</td>
    <AnimatableCell text={String(value)} className='text-right text-end' linkAction={linkAction} />
  </tr>
);

const DataRows = ({rows, boldLabel}: {rows: TableRow[]; boldLabel: boolean}) =>  (
  <>
    {rows?.map((r, i) =>
      <DataRow
        key={i}
        name={r.name}
        value={r.value}
        boldName={r.boldName || boldLabel}
        linkAction={r.linkAction}
      />
    )}
  </>
);


const getValue = (dp: UserDataPointResponse) => dp.value !== null ? `${dp.value} ${dp.unit}` : (dp.stringValue || '-');

function getDpRowLabel(dp: UserDataPointResponse): string {
  let label = dp.name;
  if (!dp.found) {
    label += ' (No Data)';
  } else if (dp.commCode !== null && dp.commCode !== 0) {
    label += ` (Comm: ${dp.commCode} - ${dp.commCodeName})`;
  }
  return label;
}


