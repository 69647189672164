import React, {type FunctionComponent} from 'react';
import {components, type GroupBase, type OptionProps} from 'react-select';
import {type DropdownOption} from 'src/components/util/form-components/SearchableDropdown/SearchableDropdown';
import {SignedImageWithPopupPreview} from '../../SignedImageWithPopupPreview';

export type OptionWithThumbnailComponentType<Value> = FunctionComponent<OptionWithThumbnailComponentProps<Value>>;
export type OptionWithThumbnailComponentProps<Value> = OptionProps<DropdownOption<Value>, false, GroupBase<DropdownOption<Value>>>;
const Option = components.Option;
export const OptionWithThumbnailComponent = <Value extends any>(props: OptionWithThumbnailComponentProps<Value>) => {
  const {children, ...propsWithoutChildren} = props;
  return <Option {...propsWithoutChildren}>
    {props.data.thumbnailSrc ?
       <div className='d-flex align-items-start justify-content-between gap-3'>
       {children}
         <SignedImageWithPopupPreview thumbnailName={props.data.thumbnailSrc}  name={props.data.imageSrc} />
    </div> :
      children
    }
  </Option>;
};

