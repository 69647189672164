import { Link } from '.';
import { useApplicationType } from '../ApplicationTypeContext';
import type { GetProps } from '../components/util/Controls/wrap';


export function useNavigationLink() {

  const type = useApplicationType();
  if(type === 'Support')
    return Link;
  else
    return VanillaLink;
}

type VanillaLinkProps =  GetProps<typeof Link>;

export function VanillaLink(props: VanillaLinkProps) {
 return <a {...props} href={String(props.to)}>{props.children}</a>;
}
