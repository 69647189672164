import { delay } from '../common/util';

export async function downloadBlob(blob: Blob, filename: string) {
  /** @type {HTMLAnchorElement} */
  const link = document.createElement('a');
  link.style.display = 'none';
  link.download = filename;
  const objectUrl = window.URL.createObjectURL(blob);
  link.href = objectUrl;
  document.body.appendChild(link);
  link.click();
  await delay(50);
  link.remove();
  await delay(50);
  window.URL.revokeObjectURL(objectUrl);
  // for some reason waiting 1 second on ios prevents a safari bug where
  // all subsequent ajax requests fail with a "XMLHTTPRequest cannot load <url> due to access control checks" error.
  // waiting 1 second allows those requests to go through.
  await delay(1_000);
}
