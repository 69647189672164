import React, {type CSSProperties, useEffect, useMemo, useRef} from 'react';
import {ErrorMessage, Field, type FieldProps} from 'formik';
import styles from '../Input/Input.module.scss';
import {
  type DropdownOption,
  type DropDownOptionValue,
  SearchableDropdown
} from '../../SearchableDropdown/SearchableDropdown';
import {type FormikSetFieldTouched, type FormikSetFieldValue} from 'src/util';
import {typedMemo} from 'src/util/react-util';
import {type LoggingProps} from 'src/components/util/Logging';

export type OnSelect<T> = ((name: string, selectedValue: T) => void) |
  ((name: string, selectedValue: T, setFieldValue: FormikSetFieldValue) => void);

export interface DropdownInputProps<Value extends DropDownOptionValue> extends LoggingProps {
  name: string;
  dropdownData: Array<DropdownOption<Value>>;
  onSelect?: OnSelect<Value>;
  isClearable?: boolean;
  disabled?: boolean;
  defaultToFirstOption?: boolean;
  defaultToFirstOptionIfOnlyOneOption?: boolean;
  showImagesInOptions?: boolean;
  defaultValue?: Value;
  placeholder?: string;
  style?: CSSProperties;
}

const isNotSelected = (value: any) => (value === '' || value === null || value === undefined);
const isNotSelectedAndOptionsAvailable = <T extends any>(value: T, options: DropdownOption<T>[]) =>
  (!valueInOptions(value, options)) && options && options.length > 0;

const valueInOptions = <T extends any>(value: T, options: DropdownOption<T>[]) => {
  if (!options) {
    return true;
  }
  return options.map(o => o.value).includes(value);
};

export const DropdownInput = <Value extends DropDownOptionValue>(props: DropdownInputProps<Value>) => {
  const {
    name, disabled, dropdownData, defaultToFirstOption,
    defaultToFirstOptionIfOnlyOneOption, isClearable, onSelect, defaultValue
  } = props;
  const allowNulls = useMemo(() => dropdownData.some(o => o.value === null), [dropdownData]);
  return (

    <React.Fragment>
      <Field name={props.name}>
        {({meta, form}: FieldProps) => {
          const {setFieldValue, setFieldTouched} = form;
          return (
            <InnerDropDownInput
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              disabled={!!disabled}
              dropdownData={dropdownData}
              name={name}
              value={meta.value as unknown}
              touched={meta.touched}
              error={!!meta.error}
              isClearable={!!isClearable}
              onSelect={onSelect}
              placeholder={props.placeholder}
              showImagesInOptions={props.showImagesInOptions}
              allowNulls={allowNulls}
              style={props.style}
              defaultValue={defaultValue}
              defaultToFirstOptionIfOnlyOneOption={!!defaultToFirstOptionIfOnlyOneOption}
              defaultToFirstOption={!!defaultToFirstOption}
              loggingEnabled={props.loggingEnabled}
            />
          );
        }
        }
      </Field>
      <ErrorMessage
        render={msg => <div className={styles['form-errors']}>{msg}</div>}
        name={props.name}
      />
    </React.Fragment>
  );
};

type InnerDropDownInputProps<Value extends DropDownOptionValue> = {
  touched: boolean;
  error: boolean;
  disabled: boolean;
  isClearable: boolean;
  setFieldValue: FormikSetFieldValue;
  setFieldTouched: FormikSetFieldTouched;
  dropdownData: Array<DropdownOption<Value>>;
  name: string;
  onSelect?: OnSelect<Value>;
  value: any;
  defaultToFirstOptionIfOnlyOneOption: boolean;
  defaultToFirstOption: boolean;
  showImagesInOptions?: boolean;
  allowNulls?: boolean;
  placeholder?: string;
  defaultValue?: Value;
  style?: CSSProperties;
} & LoggingProps;

const InnerDropDownInput = typedMemo(<Value extends DropDownOptionValue>(props: InnerDropDownInputProps<Value>) => {
  const {
    name, disabled, error, isClearable, setFieldValue: setFieldValueFormik,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    setFieldTouched, touched, dropdownData, onSelect: onSelectFromProps, value,
    defaultToFirstOptionIfOnlyOneOption, defaultToFirstOption, allowNulls, defaultValue
  } = props;
  // prevented an endless rendering loop. useState wouldn't work , only a ref would work. Not sure why.
  const defaultRef = useRef(false);
  useEffect(() => {
    if(defaultRef.current)
      return;
    if (isNotSelectedAndOptionsAvailable(value, dropdownData) && (defaultToFirstOption ||
      (defaultToFirstOptionIfOnlyOneOption && dropdownData.length === 1))) {
      defaultRef.current = true;
      setFieldValueFormik(name, dropdownData[0].value);
    }
  }, [setFieldValueFormik, value, dropdownData, defaultToFirstOption, defaultToFirstOptionIfOnlyOneOption, name]);

  const setFieldValue: FormikSetFieldValue = useMemo(() => (nameParam: string, valueParam: any, shouldValidate?: boolean) => {
    setFieldValueFormik(nameParam, valueParam, shouldValidate);
    setTimeout(() => { setFieldTouched(nameParam, true, true); }, 1);
  }, [setFieldValueFormik, setFieldTouched]);

  const onSelect = useMemo(() => (selectedValue: Value) => {
    setFieldValue(name, !isNotSelected(selectedValue) ? selectedValue : defaultValue === undefined ?  '' : defaultValue, true);
    // I had to do a setTimout order to prevent validation from running on the last value
    setTimeout(() => { setFieldTouched(name); }, 1);
    if (onSelectFromProps) {
      onSelectFromProps(name, !isNotSelected(selectedValue) ? selectedValue : null as unknown as Value, setFieldValue);
    }
  }, [name, setFieldTouched, setFieldValue, onSelectFromProps, defaultValue]);
  return (
    <SearchableDropdown
      className={touched && !error ? 'valid-input' : 'invalid-input'}
      isValid={touched && !error}
      isInvalid={touched && Boolean(error)}
      disabled={disabled}
      isClearable={isClearable || false}
      dropdownData={dropdownData}
      onSelect={onSelect}
      placeholder={props.placeholder}
      showImagesInOptions={props.showImagesInOptions}
      style={props.style}
      value={
        /* eslint-disable eqeqeq */
        /* tslint:disable-next-line:triple-equals */
        !isNotSelected(value) || allowNulls ? dropdownData.find(o => o.value == value) || null : null
      }
      loggingEnabled={props.loggingEnabled}
    />
  );
});
