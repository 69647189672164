import React, { useRef, useState } from 'react';
import { Tabs } from '../../utils/Tabs/Tabs';
import { useModal } from '../../hooks/useModal';
import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { RmxServiceContextProvider } from './RmxServiceContext';
import { getApiKey } from '../../utils/ajax';
import { getUserInformation } from '../../utils';
import { Button } from 'react-bootstrap';
import { RmxServiceExpenses } from '../RmxServiceExpenses/RmxServiceExpenses';
import { MyWorkPartStatusOnboardingGuide, type PartStatusColumnHeaderRefType } from '../../onboardings/MyWorkPartStatusOnboardingGuide';
import { isNull } from '../../../common/util';
import { useOnboardingContext } from '../../onboardings/OnboardingContext';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../components/util/widgets/IconButton/IconButton';
import { MyPartsDropdownGuide } from '../../onboardings/MyPartsDropdownGuide';
import { ActivityTable } from '../ActivityTable/ActivityTable';
import { QuoteTable } from '../QuoteTable/QuoteTable';
import { MyWorkTable } from '../MyWorkTable/MyWorkTable';
import { TimeTable } from '../TimeTable/TimeTable';
import { MyPartsTable } from '../MyPartsTable/MyPartsTable';
import { MyFlagTable } from '../MyFlagTable/MyFlagTable';
import type { ServiceFilterParams } from './RmxServiceComponentModal';
import { SamplesTable } from '../SamplesTable/SamplesTable';

interface Props {
  params?: ServiceFilterParams;
}
export function RmxServiceComponent(props: Props) {
  const inRmxServicePage = props.params?.assetId === undefined && props.params?.siteId === undefined && props.params?.companyId === undefined;
  // const { assetId } = getParams(); // gets assetId from url params

  // Modal states
  // const partModal = useModal<RmxServicePartModalParam>();
  // const sampleModal = useModal<RmxServiceSampleModalParam>();
  // const quoteModal = useModal<Quote>();
  // const invoiceModal = useModal();

  const rmxExpenseModal = useModal();
  const [activeTab, setActiveTab] = useState<string>(inRmxServicePage ? 'work' : 'activity');

  const userInformation = getUserInformation(getApiKey()!);
  // @ts-ignore
  const userRoles = userInformation?.role as string | string[];

  // const partsTable = useServerDataTable({
  //   ...sharedTableProps,
  //   queryKey: ['parts', assetId, partsDateFilter],
  //   enabled: activeTab === 'parts',
  //   columns: partsColumns,
  //   getRows: async (params) => {
  //     return await api.getParts({ ...params, assetId: assetId, daysFilter: partsDateFilter, activityId: null });
  //   },
  //   initialSorting: [{ desc: true, id: 'expectedOn' }]
  // });
  //
  //
  // const samplesTable = useServerDataTable({
  //   ...sharedTableProps,
  //   queryKey: ['samples', assetId, sampleDateFilter],
  //   enabled: activeTab === 'samples',
  //   columns: sampleColumns,
  //   getRows: async (params) => {
  //     return await api.getSamples({ ...params, assetId: assetId, daysFilter: sampleDateFilter });
  //   }
  //   // initialSorting: [{ id: 'Recommendations', desc: false }]
  // });

  // Required for the onboarding guide in the my parts table
  const [displayPartStatusOnboarding, setDisplayPartStatusOnboarding] = useState<boolean>(false);
  const [displayMyPartsDropdownGuide, setDisplayMyPartsDropdownGuide] = useState<boolean>(false);

  const { onBoardingTitleValueMap } = useOnboardingContext();

  const partStatusUserOnboardingInfo = onBoardingTitleValueMap?.get('MyWorkPartStatus');
  const partStatusColumnHeaderRef = useRef<HTMLDivElement>(null) satisfies PartStatusColumnHeaderRefType;
  const myPartsDropdownGuideRef = useRef<HTMLSelectElement>(null);
  const partStatusOnBoardingFirstInteraction =
    partStatusUserOnboardingInfo && partStatusUserOnboardingInfo.isActive && isNull(partStatusUserOnboardingInfo.interactionId);
  return (
    <RmxServiceContextProvider refetchServiceTable={() => Promise.resolve()} refetchMyWorkTable={() => Promise.resolve()} userRoles={userRoles}>
      {activeTab === 'work' &&
        partStatusUserOnboardingInfo &&
        (partStatusOnBoardingFirstInteraction || displayPartStatusOnboarding) &&
        partStatusColumnHeaderRef.current !== null && ( // User will not be able to interact with displayPartStatus if the partStatusOnboardingInfo is not active
          <MyWorkPartStatusOnboardingGuide
            onExit={() => setDisplayPartStatusOnboarding(false)}
            partStatusColumnHeaderRef={partStatusColumnHeaderRef}
            forceDisplay={displayPartStatusOnboarding}
          />
        )}
      {activeTab === 'myParts' && (
        <MyPartsDropdownGuide
          forceDisplay={displayMyPartsDropdownGuide}
          onExit={() => setDisplayMyPartsDropdownGuide(false)}
          dropdownRef={myPartsDropdownGuideRef}
        />
      )}
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          {
            key: 'work',
            title: 'My Work',
            content: (
              <>
                <MyWorkTable assetId={props.params?.assetId} />
              </>
            ),
            hide: !inRmxServicePage
          },
          {
            key: 'time',
            title: 'My Time',
            content: (
              <>
                <TimeTable
                  header={
                    <div>
                      <Button onClick={() => rmxExpenseModal.setIsOpen(true)} variant={'secondary'}>
                        Add Non-Activity Expense
                      </Button>
                    </div>
                  }
                />
              </>
            ),
            hide: !inRmxServicePage
          },
          {
            key: 'myParts',
            title: 'My Parts',
            hide: !inRmxServicePage,
            content: (
              <MyPartsTable
                dropDownGuideRef={myPartsDropdownGuideRef}
                header={
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <IconButton
                      icon={faQuestionCircle}
                      size='lg'
                      onClick={() => {
                        setDisplayMyPartsDropdownGuide(true);
                      }}
                    />
                  </div>
                }
              />
            )
          },
          {
            key: 'flags',
            title: 'My Flags',
            content: <MyFlagTable />,
            hide: !inRmxServicePage
          },
          {
            key: 'activity',
            title: 'Activities',
            // serviceData.length === 0 ? <div className={s['no-data']}>{NoDataLabel}</div> :
            content: <ActivityTable filterByAssetId={props.params?.assetId} filterBySiteId={props.params?.siteId} isMyWork={false} hideEllipsisButton={false} />
          },
          {
            key: 'quotes',
            title: 'Quotes',
            content: <QuoteTable filterByAssetId={props.params?.assetId} filterBySiteId={props.params?.siteId} />
          },
          // {
          //   key: 'parts',
          //   title: 'Parts',
          //   content: !partsTable ? (
          //     <Spinner />
          //   ) : (
          //     <DataTable
          //       negativeMarginsOnMobile={true}
          //       table={partsTable}
          //       onRowClick={async (row) => await partModal.open({ resource: row })}
          //       footer={<DayFilter value={partsDateFilter} setGlobalDateFilter={setPartsDateFilter} />}
          //     />
          //   )
          // },
          {
            key: 'samples',
            title: 'Samples',
            content: <SamplesTable />
          }
        ]}
      />
      {/*{partModal.isOpen && <RmxServicePartModal state={partModal} />}*/}
      {/*{sampleModal.isOpen && <RmxServiceSampleModal state={sampleModal} />}*/}
      {/*<QuoteDetailModals state={quoteModal} />*/}
      {/*<GenericModal open={invoiceModal.isOpen} setIsOpen={invoiceModal.setIsOpen} label={'Invoice Details'}>*/}
      {/*  {' '}*/}
      {/*  No PDF available*/}
      {/*</GenericModal>*/}

      <GenericModal showClose={true} open={rmxExpenseModal.isOpen} setIsOpen={rmxExpenseModal.setIsOpen} label={'Add Non-Activity Expense'}>
        <RmxServiceExpenses enabled={true} onSubmit={rmxExpenseModal.close} />
      </GenericModal>
    </RmxServiceContextProvider>
  );
}
