/* eslint-disable */
/**
 * This file handles fixing a bug in canvas gauges where if two major tick labels are too close to each other, then the labels will overlap.
 * This modification pushes the second label to the right to avoid overlapping text.
 * Note this fix only patches horizontal overlapping and not vertical overlapping labels.
 * This code file only has a small change with a few functions pulled from the following files:
 * https://github.com/Mikhus/canvas-gauges/blob/6b179f83b7f03926a4d58ac09e15525d19fad3d1/lib/RadialGauge.js
 * https://github.com/Mikhus/canvas-gauges/blob/6b179f83b7f03926a4d58ac09e15525d19fad3d1/lib/drawings.js
 * https://github.com/Mikhus/canvas-gauges/blob/6b179f83b7f03926a4d58ac09e15525d19fad3d1/lib/SmartCanvas.js
 *
 * ctrl + f for "Start of Added code" to find the modified code section
 *
 */

// pulled and converted into an object from drawings.js
const drawings = {
  /**
   * Transforms degrees to radians
   *
   * @param {number} degrees
   * @returns {number}
   */
  radians: (degrees) => {
    return degrees * Math.PI / 180;
  },
  /**
   * Calculates and returns radial point coordinates
   *
   * @param {number} radius
   * @param {number} angle
   * @returns {{x: number, y: number}}
   */
  radialPoint: (radius, angle) => {
    return { x: -radius * Math.sin(angle), y: radius * Math.cos(angle) };
  },
  /**
   * Constructs font styles for canvas fonts
   *
   * @param {GenericOptions} options
   * @param {string} target
   * @param {number} baseSize
   */
  font: (options, target, baseSize) => {
    return options['font' + target + 'Style'] + ' ' +
      options['font' + target + 'Weight'] + ' ' +
      options['font' + target + 'Size'] * baseSize + 'px ' +
      options['font' + target];
  }
}

/**
 * Drop in replacement for SmartCanvas.pixelRatio
 * @returns {number|number}
 */
function getPixelRatio() {
  return window.devicePixelRatio || 1;
}

function maxRadialRadius(context, options) {
  let pxRatio = getPixelRatio();

  if (!context.maxRadius) {
    context.maxRadius = context.max
      - options.borderShadowWidth
      - options.borderOuterWidth * pxRatio
      - options.borderMiddleWidth * pxRatio
      - options.borderInnerWidth * pxRatio
      + (options.borderOuterWidth ? 0.5 : 0)
      + (options.borderMiddleWidth ? 0.5 : 0)
      + (options.borderInnerWidth ? 0.5 : 0);
  }

  return context.maxRadius;
}

function radialTicksRadius(context, options) {
  let unit = context.max / 100;

  return maxRadialRadius(context, options) - 5 * unit -
    (options.barWidth ?
      ((parseFloat(options.barStrokeWidth) || 0) * 2 +
        ((parseFloat(options.barWidth) || 0) + 5) * unit) :
      0);
}

function radialNextAngle(options, i, s) {
  if (options.exactTicks) {
    let ratio = options.ticksAngle / (options.maxValue - options.minValue);
    return options.startAngle + ratio * (i - options.minValue);
  }

  return options.startAngle + i * (options.ticksAngle / (s - 1));
}

// Start of Added code:
export function applyRadialNumbersOverride(gauge, getMajorTicks) {
  updateRadialNumbersGetMajorTicks(gauge, getMajorTicks);
  gauge.on('beforeMajorTicks', () => {
    gauge.options.majorTicks = gauge.getMajorTicks();
  });
  gauge.on('beforeNumbers', () => {
    const context = gauge.canvas.contextClone;
    gauge.options.majorTicks = [];
    drawRadialNumbers(context, {...gauge.options, majorTicks: gauge.getMajorTicks()});
  });
}
// window.applyRadialNumbersOverride = applyRadialNumbersOverride;
export function updateRadialNumbersGetMajorTicks(gauge, getMajorTicks) {
  gauge.getMajorTicks = getMajorTicks;
}
// window.updateRadialNumbersGetMajorTicks = updateRadialNumbersGetMajorTicks;
// End of added code

function drawRadialNumbers(context, options) {
  let radius = radialTicksRadius(context, options) - context.max * 0.15;
  let points = {};
  let s = options.majorTicks.length;
  let isAnimated = options.animationTarget !== 'needle';
  let colors = options.colorNumbers instanceof Array ?
    options.colorNumbers : new Array(s).fill(options.colorNumbers);

  let plateValueAngle = isAnimated ? -(options.value - options.minValue) /
    (options.maxValue - options.minValue) * options.ticksAngle : 0;

  if (isAnimated) {
    context.save();
    context.rotate(-drawings.radians(plateValueAngle));
  }

  context.font = drawings.font(options, 'Numbers', context.max / 200);
  context.lineWidth = 0;
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  // Start of Added code:
  if(options.majorTicks.length > 0 && typeof options.majorTicks[0] === 'number')
    options.majorTicks.sort((a, b) => a - b); // sort ascending order
  const pixelToAngleRatio = 1.7 * window.devicePixelRatio;
  // End of added code
  for (let i = 0; i < s; ++i) {
    let angle = plateValueAngle + radialNextAngle(options,
      options.exactTicks ? options.majorTicks[i] : i, s);
    let textWidth = context.measureText(options.majorTicks[i]).width;
    // Start of Added code:
      let previousAngle = i === 0 ? -50 : plateValueAngle + radialNextAngle(options,
        options.exactTicks ? options.majorTicks[i - 1] : i - 1, s);

      let nextAngle = i === s - 1 ? 350 : plateValueAngle + radialNextAngle(options,
        options.exactTicks ? options.majorTicks[i + 1] : i + 1, s);
      const nextAngleDiff = nextAngle - angle;
      let nextTextWidth = context.measureText(options.majorTicks[i === s - 1 ? 0 : i + 1]).width;
      const nextAngleRequired = (textWidth / 2 + nextTextWidth) / pixelToAngleRatio;
      const angleDiff = angle - previousAngle;
      let previousTextWidth = context.measureText(options.majorTicks[i === 0 ? 0 : i - 1]).width;
      const angleRequired = (textWidth / 2 + previousTextWidth) / pixelToAngleRatio;

      const usableAngle =  angle > 90 + 15 && angle < 180 + 45;
      if(angleDiff < angleRequired && usableAngle) {
        angle += angleRequired - angleDiff;
      } else if(nextAngleDiff < nextAngleRequired) {
        angle -= nextAngleRequired - nextAngleDiff;
      }
    // End of added code
    let textHeight = options.fontNumbersSize;
    let textRadius = Math.sqrt(textWidth * textWidth +
      textHeight * textHeight) / 2;
    let point = drawings.radialPoint(radius - textRadius -
      options.numbersMargin / 100 * context.max,
      drawings.radians(angle));

    if (angle === 360) angle = 0;

    if (points[angle]) {
      continue; //already drawn at this place, skipping
    }

    points[angle] = true;

    context.fillStyle = colors[i];
    context.fillText(options.majorTicks[i], point.x, point.y);
  }

  isAnimated && context.restore();
}
