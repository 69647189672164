import React, { useMemo, useRef, useState } from 'react';
import { RmxServiceParts } from '../RmxServiceParts/RmxServiceParts';
import { RmxServiceForms } from '../RmxServiceForms/RmxServiceForms';
import { RmxServiceQuotes } from '../RmxServiceQuotes/RmxServiceQuotes';
import { RmxServiceExpenses } from '../RmxServiceExpenses/RmxServiceExpenses';
import { RmxServiceUploads } from '../RmxServiceUploads/RmxServiceUploads';
import { RmxServiceTime, type TimeInfo } from '../RmxServiceTime/RmxServiceTime';
import { RmxServiceDetails } from '../RmxServiceActivityDetails/RmxServiceDetails';
import { RmxServiceSample } from '../RmxServiceSample/RmxServiceSample';
import { type ModalState } from '../../hooks/useModal';
import { type Activity, type AssetWithDetailsResponse, type Part } from '../Service.types';
import { Tabs } from '../../utils/Tabs/Tabs';
import { RmxServiceDebrief } from '../RmxServiceDebrief/RmxServiceDebrief';
import { RmxServiceLLR } from '../RmxServiceLLR/RmxServiceLLR';
import { RmxServiceModalContextProvider } from './RmxServiceModalContext';
import { useQuery } from '@tanstack/react-query';
import { useRmxServiceApi } from '../api';
import { formatDateToTimeString } from '../../utils/date-time';
import { formatTime } from '../../../util/formatters';
import { RmxServiceHistory } from '../RmxServiceExpenses/RmxServiceHistory';
import { EllipsisDropdown } from '../../../components/util/widgets/EllipsisDropdown';
import { RmxServiceModalOptionsOnboardingGuide } from '../../onboardings/RmxServiceModalOptionsOnboardingGuide';
import { ActivityDetails } from '../RmxServiceActivityDetails/ActivityDetails';
import { ContactContextProvider } from '../../utils/Contact/ContactContext';
import { FormDataProvider } from '../RmxServiceForms/FormDataContext';
import { TabbedModal } from '../../../components/util/Modal/TabbedModal';

export type ModalTab = 'activityDetails' | 'parts' | 'llr' | 'forms' | 'quotes' | 'expenses' | 'uploads' | 'time' | 'Sample' | 'scope' | 'debrief';

export type RmxServiceActivityModalParam = {
  resource: Activity;
  activityParts?: Part[];
  disableEditingActivityDetails?: boolean;
  partLibrary?: Part[];
  enableTechEditing: boolean;
};

type Props = {
  state: ModalState<RmxServiceActivityModalParam>;
  createFlag?: (item: Activity) => void;
  openAsset?: (item: Activity) => void;
  openAssetHistory?: (item: Activity) => void;
  openQuoteHistory?: (item: Activity) => void;
  openFlagHistory?: (item: Activity) => void;
  enableTechEditing: boolean;
  hideEllipsisButton?: boolean;
};

export function RmxServiceActivityModal(props: Props) {
  const activity = props.state.param!.resource;
  const [activityParts, setActivityParts] = useState<Part[]>([]);
  const enableTechEditing = props.state.param!.enableTechEditing;
  // const { userRoles } = useRmxServiceContext();
  const [timeTableData, setTimeTableData] = useState<TimeInfo[]>([]);
  const [assetOptions, setAssetOptions] = useState<AssetWithDetailsResponse[]>([]);
  const api = useRmxServiceApi();
  const [activeTab, setActiveTab] = useState<ModalTab | string>('activityDetails');

  const {
    data: isSlamSubmitted,
    refetch: refetchSlam,
    isFetching: fetchingSlam
  } = useQuery({
    queryKey: ['getSlamSubmission', activity.id],
    queryFn: async () => await api.isSlamSubmitted(activity.id)
  });

  const { data: isAssetMonitored } = useQuery({
    queryKey: ['is-asset-monitored', activity.assetId],
    queryFn: async () => await api.isAssetMonitored(activity.assetId)
  });

  const existingUploadQuery = useQuery({
    queryKey: ['getActivityUploads', activity.id],
    queryFn: async () => await api.getActivityUploads(activity.id),
    refetchOnWindowFocus: false
  });

  const requiredUploadQuery = useQuery({
    queryKey: ['getRequiredActivityUploads', activity.id, 1],
    queryFn: async () => {
      return await api.getRequiredActivityUploads(1, 'PM');
    }
  });

  const uploadedAllRequiredUploads = !(
    requiredUploadQuery.data
      ?.map((upload) => ({
        uploaded: existingUploadQuery.data?.find((value) => value.title === upload.title && value?.id !== undefined)?.id !== undefined
      }))
      .some((value) => !value.uploaded) ?? true
  );

  const lastLoadReportExists = existingUploadQuery.data?.some((upload) => upload.title === 'Last Load Report');

  /**
   * moved these out of their respective tabs so that its possible for the data to be fetched when user navigates directly into the debrief tab
   */
  // parts tab query
  const activityPartQuery = useQuery({
    queryKey: ['activityParts', activity.id],
    queryFn: async () => {
      const parts = await api.getPartsByActivityId(activity.id);
      setActivityParts(parts);
      return parts;
    },
    enabled: activeTab === 'activityDetails' || activeTab === 'parts' || activeTab === 'debrief',
    gcTime: 0 // ensure stale data is not shown as the debrief tab only loads the data as default values and does not sync with the data.
  });

  // time tab query
  const activityTimeQuery = useQuery({
    queryKey: ['activityTime', activity.id],
    queryFn: async () => api.getActivityTime(activity.id),
    enabled: activeTab === 'time' || activeTab === 'debrief',
    refetchOnWindowFocus: false
  });

  const getExpenseHistoryQuery = useQuery({
    queryKey: ['getExpenseHistory', activity.id],
    queryFn: () => api.getActivityExpenses(activity.id),
    enabled: activeTab === 'expenses',
    refetchOnWindowFocus: false
  });

  const quoteHistoryQuery = useQuery({
    queryKey: ['getQuoteHistory', activity.assetId, activity.id],
    queryFn: () => api.getQuotesByActivityId(activity.id),
    refetchOnWindowFocus: false
  });

  const toggleRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [forceShowDropDown, setForceShowDropDown] = useState<boolean | undefined>(undefined);
  const [showModalOptionsOnboardingGuide, setShowModalOptionsOnboardingGuide] = useState<boolean>(false);
  const pickedParts = useMemo(() => activityPartQuery.data?.filter((value) => value.pickedByUserId != null) ?? [], [activityPartQuery.data]);
  const showTabs =
    activity.status === 'Archived' || activity.status === 'Voided' || activity.status === 'Completed'
      ? true
      : activity.status === 'In Progress' && isSlamSubmitted && activityPartQuery.data?.length === pickedParts.length;

  // const confirmedContactQuery = useQuery({
  //   queryKey: ['confirmedContacts', activity.id],
  //   queryFn: async () => {
  //     return await api.getConfirmedContacts(activity.id);
  //   },
  //   refetchOnWindowFocus: false,
  //   refetchOnReconnect: false
  // });

  return (
    <RmxServiceModalContextProvider
      refetchIsSlamCompleted={refetchSlam}
      refetchUploads={{ refetch: existingUploadQuery.refetch, isRefetching: existingUploadQuery.isRefetching }}
    >
      <FormDataProvider activityId={activity.id}>
        <ContactContextProvider workOrderId={activity.workOrderId} customerId={activity.customerId} agreementId={activity.agreementId}>
          <TabbedModal
            state={props.state}
            afterLabel={
              props.hideEllipsisButton ? null : (
                <EllipsisDropdown
                  forceShow={forceShowDropDown}
                  toggleRef={toggleRef}
                  menuRef={menuRef}
                  toggleClassName='ml-3'
                  items={[
                    props.openAsset && isAssetMonitored && { label: 'Open Asset', onClick: () => props.openAsset!(activity) },
                    props.createFlag && { label: 'Create Flag', onClick: () => props.createFlag!(activity) },
                    props.openAssetHistory && { label: 'Asset History', onClick: () => props.openAssetHistory!(activity) },
                    props.openQuoteHistory && { label: 'Asset Quote History', onClick: () => props.openQuoteHistory!(activity) },
                    props.openFlagHistory && { label: 'Activity Flag History', onClick: () => props.openFlagHistory!(activity) },
                    {
                      label: 'Help',
                      onClick: () => {
                        setShowModalOptionsOnboardingGuide(true);
                        setForceShowDropDown(true);
                      }
                    }
                  ]}
                />
              )
            }
            title={`Activity: ${activity.activityCrmId}`}
            showClose={true}
          >
            <RmxServiceModalOptionsOnboardingGuide
              toggleRef={toggleRef}
              menuRef={menuRef}
              setForceShowDropDown={setForceShowDropDown}
              forceDisplay={showModalOptionsOnboardingGuide}
              onExit={() => {
                setShowModalOptionsOnboardingGuide(false);
              }}
            />
            <Tabs
              setActiveTab={setActiveTab}
              withScroll={true}
              activeTab={activeTab}
              noMargin={true}
              growContent={true}
              tabs={[
                {
                  key: 'activityDetails',
                  title: 'Activity Details',
                  content: (
                    <RmxServiceDetails>
                      <ActivityDetails
                        isMyWork={props.enableTechEditing}
                        // confirmedContactQuery={confirmedContactQuery}
                        activity={activity}
                        disableEditing={(props.state.param!.disableEditingActivityDetails || isSlamSubmitted) as boolean}
                        setCustomerAndSiteAssets={setAssetOptions}
                      />
                    </RmxServiceDetails>
                  )
                },
                {
                  key: 'parts',
                  title: 'Parts',
                  hide: activity.status === 'Archived',
                  content: (
                    <RmxServiceParts
                      activityPartQuery={activityPartQuery}
                      enabled={enableTechEditing}
                      activityParts={activityParts}
                      setActivityParts={setActivityParts}
                      activity={activity}
                    />
                  )
                },
                {
                  key: 'llr',
                  title: 'LLR',
                  content: (
                    <RmxServiceLLR
                      enabled={enableTechEditing && !lastLoadReportExists}
                      activityId={activity.id}
                      assetId={activity.assetId ? activity.assetId.toString() : null}
                    />
                  )
                },
                {
                  key: 'forms',
                  title: 'Forms',
                  content: (
                    <RmxServiceForms
                      // confirmedContact={confirmedContactQuery.data?.length !== 0 || activity.contactConfirmedNoResponse}
                      pickedAllParts={activityPartQuery.data?.length === pickedParts.length}
                      enabled={enableTechEditing}
                      activity={activity}
                    />
                  )
                },
                {
                  key: 'quotes',
                  hide: !showTabs || activity.status === 'Archived',
                  loading: fetchingSlam,
                  title: 'Pick List',
                  content: (
                    <RmxServiceQuotes
                      quoteHistoryQuery={quoteHistoryQuery}
                      enabled={enableTechEditing}
                      activityId={activity.id}
                      assetId={activity.assetId}
                      assetOptions={assetOptions}
                      partLibrary={props.state.param?.partLibrary}
                    />
                  )
                },
                {
                  key: 'expenses',
                  hide: !showTabs || activity.status === 'Archived',
                  loading: fetchingSlam,
                  title: 'Expenses',
                  content: (
                    <>
                      <RmxServiceExpenses enabled={enableTechEditing} activityId={activity.id} onSubmit={getExpenseHistoryQuery.refetch} />
                      <RmxServiceHistory expenseHistoryQuery={getExpenseHistoryQuery} />
                    </>
                  )
                },
                {
                  key: 'uploads',
                  hide: !showTabs,
                  loading: fetchingSlam,
                  title: 'Uploads',
                  content: (
                    <RmxServiceUploads
                      enabled={enableTechEditing}
                      requiredUploadsQuery={requiredUploadQuery}
                      existingUploadsQuery={existingUploadQuery ?? []}
                      activityId={activity.id}
                    />
                  )
                },
                {
                  key: 'time',
                  hide: !showTabs || activity.status === 'Archived',
                  loading: fetchingSlam,
                  title: 'Time',
                  content: (
                    <RmxServiceTime
                      timeHistoryQuery={activityTimeQuery}
                      enabled={enableTechEditing}
                      activity={activity}
                      timeTableData={timeTableData}
                      setTimeTableData={setTimeTableData}
                    />
                  )
                },
                {
                  key: 'Sample',
                  loading: fetchingSlam,
                  hide: !showTabs || activity.status === 'Archived',
                  title: 'Sample',
                  content: <RmxServiceSample enabled={enableTechEditing} />
                },
                {
                  key: 'debrief',
                  loading: fetchingSlam,
                  hide: !showTabs,
                  title: 'Debrief',
                  content: (
                    <RmxServiceDebrief
                      enabled={enableTechEditing}
                      activity={activity}
                      isSlamCompleted={isSlamSubmitted}
                      uploadedAllRequiredUploads={uploadedAllRequiredUploads}
                      // Previously This props was used to validate that tech has picked all parts
                      // but is no longer necessary because debrief tab is only shown when all parts are picked
                      // activityPartCount={activityPartQuery.data?.length ?? 0}
                      fetchingPickedParts={activityPartQuery.isFetching}
                      techTime={
                        activityTimeQuery.data?.map((activityTime) => ({
                          date: activityTime.entryDate.toLocaleDateString(),
                          startTime: formatDateToTimeString(activityTime.startTime, true),
                          endTime: formatDateToTimeString(activityTime.endTime, true),
                          total: formatTime(activityTime.totalTime.getHours(), activityTime.totalTime.getMinutes()),
                          type: activityTime.type
                        })) ?? []
                      }
                      quoteHistory={quoteHistoryQuery.data ?? []}
                      setTabbedModalOpen={props.state.setIsOpen}
                      assetOptions={assetOptions}
                      pickedParts={pickedParts}
                    />
                  )
                }
              ]}
            />
          </TabbedModal>
        </ContactContextProvider>
      </FormDataProvider>
    </RmxServiceModalContextProvider>
  );
}
