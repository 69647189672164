import { type ComputedGaugeData, convertGaugeData, type GaugeData } from 'src/common/types/Gauge';
import { distinctArray, range } from 'src/common/util';
import React from 'react';
import styles from './Gauges.module.scss';
import { type Highlight } from 'canvas-gauges';
import { ReactRadialGauge } from 'src/components/util/ReactCanvasGauges/RadialGauge';

interface Props {
  gauges: GaugeData[];
  spUuid: string;
}

export function Gauges({ gauges, spUuid}: Props) {
  const computedGauges: ComputedGaugeData[] = gauges.map(g => convertGaugeData(g));
  return (
    <div className={styles['gauges-container']}>
      {computedGauges.map((gauge: ComputedGaugeData, i) => {
        return (
          <a key={i} href={`/?p=ChartingPrefill&spId=${spUuid}&dpId=${gauge.dataPointId}`}>
            <ReactRadialGauge
              value={gauge.value}
              maxValue={gauge.max}
              minValue={gauge.min}
              title={gauge.title}
              units={gauge.unit}
              exactTicks={true}
              minorTicks={makeMinorTicksNum(gauge)}
              majorTicks={makeMajorTicks(gauge)}
              highlights={makeHighlightsFromGaugeHighlights(gauge)}
              valueText={gauge.value === undefined ? 'No data' : gauge.value.toString()}
              width={300}
              height={220}
              className={styles['gauge']}
            />
          </a>
        );
      })}
    </div>
  );
}

function makeHighlightsFromGaugeHighlights(gauge: ComputedGaugeData): Highlight[] {
  const highlights: readonly Highlight[] = gauge.highlights.map<Highlight>(s => ({
    from: s.from,
    to: s.to,
    color: s.color === 'yellow' ? 'orange' : s.color === 'red' ? 'rgb(255,70,70)' : s.color
  }));

  return [
    { color: 'rgb(31, 225, 255)', from: gauge.min, to: gauge.max },
    ...highlights
  ];
}

const defaultMajorTicks = 7;

function makeEvenlySpacedMajorTicks(gauge: ComputedGaugeData): number[] {
  const gaugeSpacing = (gauge.max - gauge.min) / defaultMajorTicks;
  // do distinct because rounding can cause the same number to show multiple times.
  return distinctArray(
    range(0, defaultMajorTicks).map(index => Math.round(index * gaugeSpacing + gauge.min))
  );
}

function makeMinorTicksNum(gauge: ComputedGaugeData): number {
  const gaugeRange = gauge.max - gauge.min;
  const ticksPerSections = 5;
  return gaugeRange / (defaultMajorTicks * ticksPerSections);
}

const makeThresholdBasedMajorTicks = (gauge: ComputedGaugeData): number[] =>
  distinctArray([gauge.min, ...gauge.highlights.flatMap(h => [h.from, h.to]), gauge.max]);

function makeMajorTicks(gauge: ComputedGaugeData): number[] {
  if (gauge.highlights.length > 0) {
    return makeThresholdBasedMajorTicks(gauge);
  }
  return makeEvenlySpacedMajorTicks(gauge);
}
