import { Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import React, { type ChangeEvent, useState } from 'react';
import s from './RmxServiceExpenses.module.scss';
import { CameraOrGalleryInput } from '../../utils/CameraOrGalleryInput/CameraOrGalleryInput';
import { useRmxServiceApi } from '../api';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { CreateActivityExpenseRequest } from '../Service.types';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import { VerticalInputRow } from 'src/components/util/form-components/VerticalInputRow';
import { EParentResourceType } from '../../../common/util/files';

interface Props {
  activityId?: string;
  enabled: boolean;
  onSubmit: () => void;
}
export function RmxServiceExpenses({ enabled, activityId, onSubmit }: Props) {
  const [paymentMethod, setPaymentMethod] = useState('Select');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [vendor, setVendor] = useState('');
  const [tax, setTax] = useState('');
  const [receipt, setReceipt] = useState<File[] | null>(null);

  const [fileUploadKey, setFileUploadKey] = useState(0);
  const [expenseTypeId, setExpenseTypeId] = useState<number | undefined | null>(activityId ? null : undefined);
  const api = useRmxServiceApi();

  const paymentMethodOptions = (
    <>
      <option>Select</option>
      <option value='Cash'>Cash</option>
      <option value='Card'>Card</option>
      <option value='PO'>PO</option>
      <option value='Mileage'>Mileage</option>
    </>
  );

  const expenseTypeOptions = (
    <>
      <option value=''>Select</option>
      <option value='6280'>Auto - Fuel</option>
      <option value='6281'>Auto - Maintenance</option>
      <option value='5100'>COS - Parts</option>
      <option value='6900'>Entertainment</option>
      <option value='7240'>Maintenance and Repairs - Equipment</option>
      <option value='7380'>Office Expense</option>
      <option value='7680'>Shop Tools and Expense</option>
      <option value='7880'>Travel</option>
      <option value='7940'>Uptime RMX</option>
    </>
  );

  const handleAmountChange = (event: ChangeEvent) => {
    const newValue = (event.target as HTMLInputElement).value;
    const pattern = /^\d{1,5}(\.\d{0,2})?$/; // up to 5 digits before decimal point, and up to 2 digits after decimal point
    const regex = new RegExp(pattern);

    if (regex.test(newValue) || newValue === '') {
      // Allow empty string for backspace/delete
      setAmount(newValue);
    }
  };

  const { mutateAsync: createActivityExpense, isPending } = useMutation({
    mutationFn: async (req: CreateActivityExpenseRequest) => {
      await api.createActivityExpense(req, receipt ?? []);
      onSubmit();
    }
  });

  const handleSubmit = async () => {
    switch (true) {
      case description === '':
        alert('Please enter a description');
        return;
      case amount === '':
        alert('Please enter an amount');
        return;
      case paymentMethod === 'Select':
        alert('Please select a payment method');
        return;
      case vendor === '':
        alert('Please enter a vendor');
        return;
      case tax === '':
        alert('Please enter a sales tax amount');
        return;
      case receipt === null:
        alert('Please upload a receipt');
        return;
      case expenseTypeId === undefined:
        alert('Please select an expense type');
        return;
      default:
        const request = {
          id: activityId,
          description,
          amount: parseFloat(amount),
          saleTaxAmount: parseFloat(tax),
          vendorName: vendor,
          paymentType: paymentMethod,
          expenseTypeId: expenseTypeId
        } satisfies CreateActivityExpenseRequest;

        await createActivityExpense(request);
        // Reset form fields
        setDescription('');
        setAmount('');
        setVendor('');
        setTax('');
        setReceipt(null);
        setFileUploadKey((s) => s + 1);
        setPaymentMethod('Select');
        break;
    }
  };

  // query here as placeholder for when we implement autosave expenses
  const getExpenseUploads = useQuery({
    queryKey: ['expenseUploads', activityId],
    queryFn: async () => {
      return await api.getFiles(EParentResourceType.ActivityExpenseType, ''); // use expense id here
    },
    refetchOnWindowFocus: false,
    enabled: false
  });

  return (
    <div>
      <Form>
        <Row className='mb-3'>
          <Col>
            <Form.Label>Date</Form.Label>
            <InputGroup.Text className={s['text']}>{new Date().toDateString()}</InputGroup.Text>
          </Col>
          <Col>
            <Form.Label>Payment Method</Form.Label>
            <Form.Select disabled={!enabled} value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} className={s['form-select']}>
              {paymentMethodOptions}
            </Form.Select>
          </Col>
        </Row>

        {!activityId && (
          <VerticalInputRow label='Expense Type'>
            <Form.Select
              disabled={!enabled}
              value={expenseTypeId == null ? '' : expenseTypeId}
              onChange={(e) => setExpenseTypeId(parseInt(e.target.value))}
              className={s['form-select']}
            >
              {expenseTypeOptions}
            </Form.Select>
          </VerticalInputRow>
        )}
        <VerticalInputRow label='Description'>
          <Form.Control
            disabled={!enabled}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={'Explain the purpose of this expense'}
            as='textarea'
            aria-label='With textarea'
          />
        </VerticalInputRow>
        <Row className='mb-3'>
          <Col>
            <Form.Group>
              <Form.Label>Sub Total</Form.Label>
              <InputGroup className='w-auto'>
                <InputGroup.Text>$</InputGroup.Text>
                <FormControl
                  disabled={!enabled}
                  type='text'
                  datatype={'currency'}
                  size={'sm'}
                  placeholder={'0.00'}
                  value={amount}
                  style={{ borderRadius: '4px' }}
                  onChange={handleAmountChange}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group>
              <Form.Label>Sales Tax</Form.Label>
              <InputGroup className='w-auto'>
                <InputGroup.Text>$</InputGroup.Text>
                <FormControl
                  disabled={!enabled}
                  type='text'
                  datatype={'currency'}
                  size={'sm'}
                  placeholder={'0.00'}
                  value={tax}
                  style={{ borderRadius: '4px' }}
                  onChange={(event) => setTax(event.currentTarget.value)}
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <VerticalInputRow label='Vendor'>
          <FormControl
            disabled={!enabled}
            type='text'
            placeholder={'Name Of Vendor Purchased From'}
            size={'sm'}
            value={vendor}
            onChange={(e) => setVendor(e.target.value)}
            style={{ borderRadius: '4px' }}
          />
        </VerticalInputRow>

        <VerticalInputRow label='Receipt' className='mb-5'>
          <CameraOrGalleryInput
            showFileMosaicWithDownload={false}
            getUploadMediaQuery={getExpenseUploads}
            key={fileUploadKey}
            disabled={!enabled}
            setFiles={(file) => setReceipt([file])}
          />
        </VerticalInputRow>

        <LoadingButton
          variant='secondary'
          disabled={!enabled}
          className='mb-3 w-100'
          loading={isPending}
          onClick={async () => await handleSubmit()}
          label='Submit'
        />
      </Form>
    </div>
  );
}
