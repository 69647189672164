import { Button } from 'react-bootstrap';
import React from 'react';
import { pickFile } from '../pickFile';

interface Props {
  handleFileChange: (files: File) => void;
  disabled?: boolean;
}

export function FileUploadButtons({ handleFileChange, disabled }: Props) {
  const triggerFileInput = async (action: 'upload' | 'takePhoto') => handleFileChange((await pickFile('image/*,.pdf', false, action === 'takePhoto'))[0]);
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
      <Button disabled={disabled} onClick={() => triggerFileInput('upload')} variant='secondary'>
        Upload Photo
      </Button>
      <Button disabled={disabled} onClick={() => triggerFileInput('takePhoto')} variant='secondary'>
        Take Photo
      </Button>
    </div>
  );
}
