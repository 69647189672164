import { GenericModal } from '../../utils/GenericModal/GenericModal';
import { Alert, Form } from 'react-bootstrap';
import { CameraOrGalleryInput } from '../../utils/CameraOrGalleryInput/CameraOrGalleryInput';
import { LoadingButton } from '../../../components/util/widgets/LoadingButton/LoadingButton';
import React, { useState } from 'react';
import type { ModalState } from '../../hooks/useModal';
import type { IActivityUpload } from '../api/portal-api';
import { compressImageFile } from '../../utils/image-utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ModalHoc } from '../../utils/Modal/ModalHoc';
import { useRmxServiceApi } from '../api';
import { handleAxiosError } from '../../utils/http';
import { VerticalInputRow } from '../../../components/util/form-components/VerticalInputRow';
import { CheckBox } from '../../shared-compat/utils/CheckBox/CheckBox';
import { EParentResourceType } from '../../../common/util/files';

type RmxServiceUploadModalProps = {
  state: ModalState<RmxServiceUploadModalParam, any>;
  onExit?: () => void;
  onClose: () => void;
  enabled: boolean;
  deleteActivityUpload: (id: string) => Promise<void>;
  onDeleteRefetch: () => Promise<void>;
};

export type RmxServiceUploadModalParam = IActivityUpload & { disableTitle: boolean; activityId: string; disabled: boolean };

export const RmxServiceUploadModal = ModalHoc(function (props: RmxServiceUploadModalProps) {
  const api = useRmxServiceApi();
  const close = props.state.close;
  const upload = props.state.param!;
  const { enabled } = props;
  const [title, setTitle] = useState(upload.title);
  const [files, setFiles] = useState<Blob[]>([]);

  const [noUploadReason, setNoUploadReason] = useState(upload.noUploadReason ?? null);
  const getUploadMediaQuery = useQuery({
    queryKey: ['uploads', upload.id, EParentResourceType.ActivityUploadType],
    queryFn: async () => await api.getFiles(EParentResourceType.ActivityUploadType, upload.id!),
    enabled: upload.id !== undefined
  });

  const handleUpload = async (files: File[]) => {
    const compressedFiles = await Promise.all(files.map(async (file) => (file.type.includes('image/') ? await compressImageFile(file) : file)));
    setFiles(compressedFiles);
  };

  const {
    mutateAsync: upsertActivityUploads,
    isPending: pendingUpsertActivityUpload,
    error
  } = useMutation({
    mutationFn: async (activityUpload: IActivityUpload) => await api.upsertActivityUploads(activityUpload, files)
  });

  const handleSubmit = async () => {
    if (title === '') {
      alert('Please enter a title to add upload');
      return;
    }
    if ((getUploadMediaQuery.data?.files?.length ?? 0) !== 0) {
      alert('A file has already been uploaded for this activity, please delete the existing file before uploading a new one');
      return;
    }

    if (files.length === 0 && (noUploadReason === null || noUploadReason?.trim() === '')) {
      alert('Please upload a file to add upload or enter a reason for not uploading');
      return;
    }

    if (files.length > 0 && noUploadReason !== null && (noUploadReason?.trim() !== '' || noUploadReason?.trim() === '')) {
      alert('Please upload a file or enter a reason for not uploading, not both');
      return;
    }

    const updatedUpload: IActivityUpload = {
      id: upload.id,
      activityId: upload.activityId,
      title: title,
      noUploadReason: noUploadReason ?? undefined
    };
    await upsertActivityUploads(updatedUpload);
    close();
    props.onClose(); // placing props onClose function here because when passed into GenericModal props.OnClose does not execute when the above close() function is called
    // TODO investigate ^
  };
  return (
    <GenericModal state={props.state} showHeader={true} showClose={true} onExit={props.onExit}>
      <Form>
        <VerticalInputRow label='Title'>
          <Form.Control type='text' placeholder='Enter Title' value={title} disabled={upload.disableTitle} onChange={(e) => setTitle(e.target.value)} />
        </VerticalInputRow>
        <VerticalInputRow label='Upload'>
          {
            <CameraOrGalleryInput
              onFileDelete={() => props.deleteActivityUpload(upload.id!)}
              disabled={props.state.param?.disabled || noUploadReason !== null}
              setFiles={(file) => handleUpload([file])}
              getUploadMediaQuery={getUploadMediaQuery}
              onDeleteRefresh={props.onDeleteRefetch}
              showFileMosaicWithDownload={true}
            />
          }
        </VerticalInputRow>
        <VerticalInputRow>
          <CheckBox
            value={noUploadReason !== null}
            enabled={
              // no files to be uploaded, no files have been uploaded, and is enabled
              files.length === 0 && (getUploadMediaQuery.data?.files?.length ?? 0) === 0 && enabled
            }
            onChange={function (checked: boolean): void {
              setNoUploadReason(checked ? '' : null);
            }}
            label={'No Upload'}
          />
        </VerticalInputRow>
        {noUploadReason !== null && (
          <VerticalInputRow label='Reason'>
            <Form.Control value={noUploadReason} onChange={(e) => setNoUploadReason(e.currentTarget.value)} placeholder='Enter Reason' as='textarea' />
          </VerticalInputRow>
        )}
        {error && <Alert variant='danger'>{handleAxiosError(error)}</Alert>}
        {pendingUpsertActivityUpload && <Alert variant='info'>Uploading Please Do Not Refresh...</Alert>}
        <LoadingButton variant='secondary' disabled={!enabled} loading={pendingUpsertActivityUpload} onClick={handleSubmit} label='Submit' />
      </Form>
    </GenericModal>
  );
});
