import React, {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {SignedImage} from 'src/components/util/SignedImage';

type Props = {
  name: string;
  x: number;
  y: number;
};

export function ImagePreviewPopup({name, x, y}: Props) {
  console.log('x,y', x,y);
  const [element] = useState(() => {
    const el = document.createElement('div');
    const newY = Math.max(y - 200, 50);
    const newX = Math.max(Math.min(x + 200, window.innerWidth - 200), 50);
    el.style.position = 'fixed';
    el.style.top = `${newY}px`;
    el.style.left = `${newX}px`;
    el.style.zIndex = '1000';
    el.style.width = window.innerWidth - newX + 'px';
    el.style.height = window.innerHeight - newY + 'px';
    document.body.append(el);
    return el;
  });
  useEffect(() => {
    return () => {
      element.remove();
    };
  }, [element]);
  return (
    createPortal(
      <SignedImage style={{maxHeight: '100%', maxWidth: '100%'}} name={name}/>,
      element
    )
  );
}
