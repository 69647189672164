import React, {type RefObject, useEffect, useRef, useState} from 'react';
import {RadialGauge, type RadialGaugeOptions} from 'canvas-gauges';
import {applyRadialNumbersOverride, updateRadialNumbersGetMajorTicks} from './RadialGaugeLabelOverride';
import {useDeepCompareEffect} from 'src/common/redux/utils';
type Props = Omit<RadialGaugeOptions, 'renderTo'> & {className?: string};

const getOptions = (props: Props, canvasRef: RefObject<HTMLCanvasElement>): RadialGaugeOptions => ({
  ...props,
  renderTo: canvasRef.current!,
  width: 300,
  height: 220,
  title: '',
  units: props.title,
  minValue: props.minValue,
  maxValue: props.maxValue,
  value: props.value === null || props.value === undefined ? props.minValue : props.value,
  valueText: props.value === null || props.value === undefined  ? '--' : (`${props.value} ${props.units}`),
  majorTicks: props.majorTicks,
  minorTicks: props.minorTicks,
  ticksAngle: 170,
  startAngle: 95, // 45
  strokeTicks: true,
  highlights: props.highlights,
  valueInt: 1,
  valueDec: 0,
  colorPlate: '#26323a', // 333
  colorPlateEnd: '#26323a',
  colorMajorTicks: '#fff',
  colorMinorTicks: '#fff',
  colorTitle: '#fff',
  colorUnits: '#fff',
  colorNumbers: '#fff',
  valueBox: true,
  valueBoxWidth: 0,
  valueBoxBorderRadius: 0,
  colorValueBoxRect: 'transparent',
  colorValueBoxRectEnd: 'transparent',
  colorValueBoxBackground: 'transparent',
  colorValueBoxShadow: false as unknown as string,
  colorValueText: '#fff',
  colorValueTextShadow: true as unknown as string,
  colorNeedleShadowUp: true as unknown as string,
  colorNeedleShadowDown: true as unknown as string,
  colorNeedle: '#fff',
  colorNeedleEnd: '#fff',
  colorNeedleCircleOuter: 'transparent',
  colorNeedleCircleOuterEnd: 'transparent',
  borderShadowWidth: 0,
  borders: false,
  borderInnerWidth: 0,
  borderMiddleWidth: 0,
  borderOuterWidth: 0,
  needleType: 'line',
  needleStart: 0,
  needleEnd: 57,
  needleWidth: 2,
  needleCircleSize: 9,
  needleCircleOuter: true,
  needleCircleInner: false,
  fontTitle: 'sans-serif',
  fontUnits: 'sans-serif',
  fontValue: 'sans-serif',
  fontTitleSize: 24,
  fontUnitsSize: 20,
  fontValueSize: 35,
  animation: true,
  /*    animationTarget: 'plate',*/
  animationRule: 'bounce',
  animationDuration: 1000,
  animatedValue: true,
  animateOnInit: false,
  exactTicks: true
});

export const ReactRadialGauge = React.memo((props: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [gauge, setGauge] = useState<RadialGauge | null>(null);
  const getMajorTicks = () => getOptions(props, canvasRef).majorTicks;
  useEffect(() => {
    if (canvasRef.current && gauge === null) {
      const tempGauge = new RadialGauge(getOptions(props, canvasRef));
      applyRadialNumbersOverride(tempGauge, getMajorTicks);
      tempGauge.draw();
      setGauge(tempGauge);
    }
    // eslint-disable-next-line
  }, [canvasRef, gauge, setGauge]);
  useDeepCompareEffect(() => {
    if (gauge) {
      gauge!.value = props.value!;
      updateRadialNumbersGetMajorTicks(gauge, getMajorTicks);
      gauge!.update(getOptions(props, canvasRef));
    }
  }, props);
  return <canvas className={props.className} ref={canvasRef} />;
});

ReactRadialGauge.displayName = 'ReactRadialGauge';
