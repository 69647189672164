import React, {type CSSProperties, useMemo} from 'react';
import Select, {components} from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppTheme} from 'src/appTheme';
import {combineClasses} from 'src/util';
import styles from './SearchableDropDown.module.scss';
import {typedMemo} from 'src/util/react-util';
import {type OnChangeValue} from 'react-select/dist/declarations/src/types';
import {type LoggingProps} from 'src/components/util/Logging';
import {OptionWithThumbnailComponent, type OptionWithThumbnailComponentType} from 'src/components/util/form-components/SearchableDropdown/OptionWithThumbnailComponent';

export interface DropdownOption<Value extends NullableDropDownOptionValue = DropDownOptionValue> {
  value: Value;
  label: string;
  hide?: boolean;
  thumbnailSrc?: string;
  imageSrc?: string;
}
export type DropdownOptions<Value extends NullableDropDownOptionValue = DropDownOptionValue> = Array<DropdownOption<Value>>;

export type DropDownOptionValue<T = any> = T | string | number | boolean;
export type NullableDropDownOptionValue<T = any> = DropDownOptionValue<T> | null;

interface Props<Value extends NullableDropDownOptionValue> extends LoggingProps {
  dropdownData: Array<DropdownOption<Value>>;
  defaultValue?: DropdownOption<Value>;
  disabled?: boolean;
  isClearable?: boolean;
  isValid?: boolean;
  showImagesInOptions?: boolean;
  isInvalid?: boolean;
  onSelect?: (selectedValue: Value) => void;
  value?: DropdownOption<Value> | null;
  className?: string;
  nonFormikError?: string;
  placeholder?: string;
  style?: CSSProperties;
  loggingEnabled?: boolean;
}

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={'search'}/>
      </components.DropdownIndicator>
    )
  );
};

export const SearchableDropdown = typedMemo(<Value extends NullableDropDownOptionValue>(props: Props<Value>) => {
  const {onSelect, dropdownData, defaultValue, disabled} = props;
  const className = combineClasses(props.className, styles['dropdown'], props.isValid ? '-valid' : '-invalid');

  const handleChange = useMemo(() => (selectedOption: OnChangeValue<DropdownOption<Value>, false>) => {
    if (onSelect) {
      const option = (selectedOption as DropdownOption<Value>);
      const value = option ? option.value : null as unknown as Value;
      onSelect(value);
    }
  }, [onSelect]);

  return (
    <>
      <Select
        className={className}
        // errorText={props.isInvalid}
        components={{DropdownIndicator, ...(props.showImagesInOptions ? {Option: OptionWithThumbnailComponent as OptionWithThumbnailComponentType<Value>} as const : {})}}
        value={props.value}
        onChange={handleChange}
        options={dropdownData.filter(d => d.hide !== true)}
        defaultValue={defaultValue}
        isDisabled={disabled}
        placeholder={props.placeholder}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: 'orangered',
            primary25: AppTheme.colors.gray400,
            primary: AppTheme.colors.primary
          }
        })}
        minMenuHeight={500}
        isClearable={props.isClearable || false}
        styles={{
          container: (provided) => ({
            color: 'black',
            ...provided,
            ...(props.isInvalid ? {
                border: '1px solid red',
                borderRadius: '4px'
              } : {}),
            ...(props.style ? props.style : {}),
          }),
          menuPortal: (provided) => ({ ...provided, zIndex: 9999, color: 'black' })
        }}
        menuPortalTarget={document.body}
      />
      {props.nonFormikError ?  <div className={'Input_form-errors__1w0oO'}>{props.nonFormikError}</div> : null}
    </>
  );
});

