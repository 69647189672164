import React, {type ImgHTMLAttributes, type MouseEvent, useCallback, useState} from 'react';
import {ImagePreviewPopup} from './ImagePreviewPopup';
import {type FallBackComponentProps, SignedImage} from './SignedImage';
type Props = {thumbnailName: string; name?: string|null} & ImgHTMLAttributes<any> & FallBackComponentProps;

export function SignedImageWithPopupPreview({thumbnailName, name, fallback, ...imgProps}: Props) {
  const [position, setPosition] = useState<{x: number; y: number}|null>(null);
  const onMouseOver = useCallback((event: MouseEvent) => {
    setPosition({x: event.clientX, y: event.clientY});
  }, []);
  const onMouseOut = useCallback(() => {
    setPosition(null);
  }, []);
  return <>
    <SignedImage fallback={fallback} onMouseOut={onMouseOut} onMouseOver={onMouseOver} width={150} name={thumbnailName} {...imgProps} />
         {position && name && <ImagePreviewPopup name={name} {...position} />}
  </>;
}
