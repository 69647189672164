import {type SystemProcess} from 'src/common/redux/entities/installationEntities/spRevision';
import {type UserViewAsset} from 'src/common/redux/entities/installationEntities/assetRevision';
import {type GaugeData} from 'src/common/types/Gauge';
import {Gauges} from '../Gauges/Gauges';
import {InstallationAssetSection} from '../AssetSection/AssetSection';
import {Section} from 'src/components/util/Section/Section';
import {getLinkByOrgLevel} from '../../../../DashboardRoutePaths';
import React from 'react';
import {GridItem} from 'src/components/util/GridItem';
import {type ReloadableProps} from 'src/util/ReloadableProps';

export interface SystemProcessData {
  systemProcess: SystemProcess;
  primaryAssetGroup: string;
  assets: {[assetGroup: string]: UserViewAsset[]};
  gauges: GaugeData[];
  children: SystemProcess[];
}

interface Props extends ReloadableProps {
  spData: SystemProcessData;
}

export function SystemProcessComponent({spData, reload}: Props) {
  const getPrimaryAssetSection = () => spData!.assets[spData!.primaryAssetGroup];
  const getOtherAssetGroups = () => Object.keys(spData!.assets).filter(key => key !== spData!.primaryAssetGroup);
  return (
    <>
      <Gauges gauges={spData!.gauges} spUuid={spData.systemProcess.uuid} />
      {getPrimaryAssetSection() && <InstallationAssetSection reload={reload} sectionLabel={spData!.primaryAssetGroup} assets={getPrimaryAssetSection()}/>}
      {getOtherAssetGroups().map((key: string) => <InstallationAssetSection reload={reload} key={key} sectionLabel={key} assets={spData.assets[key]}/>)}
      {spData!.children.length > 0 && (
        <Section title='System Processes'>
          {spData!.children.map(sp =>
            <GridItem xl={3} key={sp.id} title={sp.name} link={getLinkByOrgLevel(sp.uuid, sp.name, 'SystemProcess')} />)}
        </Section>
      )}
    </>
  );
}
