import React from 'react';
import {type GridItemProps, DashboardGridItem} from '../DashboardGridItem/DashboardGridItem';
import s from './Grid.module.scss';
import {combineClasses} from 'src/util';

type GridProps = {
  items?: GridItemProps[];
  noItemsText: string;
  leftAlign?: boolean;
};

export function DashboardGrid({items, noItemsText, leftAlign}: GridProps) {
  return (
    <div className={combineClasses(s['container'], leftAlign ? s['left-align'] : null)}>
      {!items || items.length === 0 ? noItemsText :
        items.map((item, i) => <DashboardGridItem key={i} {...item} />)
      }
    </div>
  );
}
